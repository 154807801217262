import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { protectedResources } from '../auth-config';

@Injectable({
  providedIn: 'root',
})

export class ForensicTeamManagementService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  url = protectedResources.apiBaseUrl.endpoint;


  // ==================================================================
  // 
  // ==================================================================
  getTeamManagementData() {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.get(
      this.url + '/api/UserManagement/TeamManagementData',
      httpPostOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  disableEnableUser(userID: string, userEnabled: boolean) {
    const formData = new FormData();
    formData.append('userIDToUpdate', userID);
    formData.append('userEnabled', userEnabled.toString());

    const httpPostOptions = {
      withCredentials: true,
    };

    return this.http.patch(
      this.url + '/api/UserManagement/DisableUser',
      formData,
      httpPostOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  addUserAsAdmin(userID: string) {
    const formData = new FormData();
    formData.append('userIDToUpdate', userID);

    const httpPostOptions = {
      withCredentials: true,
    };

    return this.http.patch(
      this.url + '/api/UserManagement/AddUserAsAdmin',
      formData,
      httpPostOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  removeUserAsAdmin(userID: string) {
    const formData = new FormData();
    formData.append('userIDToUpdate', userID);

    const httpPostOptions = {
      withCredentials: true,
    };

    return this.http.patch(
      this.url + '/api/UserManagement/RemoveUserAsAdmin',
      formData,
      httpPostOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  resetPasswordToTemp(userID: string) {
    const httpPostOptions = {
      withCredentials: true,
    };

    return this.http.post(
      this.url +
      '/api/UserManagement/ResetPasswordToTemporary?userIDToUpdate=' +
      userID,
      {},
      httpPostOptions
    );
  }



  // ==================================================================
  // 
  // ==================================================================
  CreateSelfServeUser(name: string, email: string, phone: string, contryCode: any) {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.post(
      this.url +
      '/api/UserManagement/CreateSelfServeUser?UsersLegalName=' +
      name +
      '&Email=' +
      email +
      '&CellNumber=' +
      phone +
      '&CellNumberCountryCode=' +
      contryCode,
      {},
      httpPostOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  createUser(name: string, email: string, phone: string, countryCode: number) {
    const httpPostOptions = {
      withCredentials: true,
    };

    // ---------------------------------------------------------
    // Clean up phone number so it will go into the B2C MFA.
    // Strip out all Non-Numeric values
    // ---------------------------------------------------------
    phone = phone.replace(/\D/g, '');

    console.log("countryCode", countryCode);
    console.log("phone", phone);

    return this.http.post(
      this.url +
      '/api/UserManagement/CreateUser?UsersLegalName=' +
      name +
      '&Email=' +
      email +
      '&CellNumber=' +
      phone +
      '&CellNumberCountryCode=' +
      countryCode,
      {},
      httpPostOptions
    );
  }


  // ==================================================================
  // 
  // ==================================================================
  changePhoneNumber(userID: string, phone: string, countryCode: number) {

    // ---------------------------------------------------------
    // Clean up phone number so it will go into the B2C MFA.
    // Strip out all Non-Numeric values
    // ---------------------------------------------------------
    phone = phone.replace(/\D/g, '');

    console.log("countryCode", countryCode);
    console.log("phone", phone);



    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.patch(
      this.url +
      '/api/UserManagement/UpdateMFA?userIDtoUpdate=' +
      userID +
      '&CellNumberNew=' +
      phone +
      '&CellNumberCountryCodeNew=' +
      countryCode,
      {},
      httpPostOptions
    );
  }


  getCMSUsers() : Observable<any>{
    // Code to determine which users to get based on enum parameter.
    // for now, just return DF.

    return this.getDigitalForensicsTeamMembers();
  }

  // ==================================================================
  // NOTE: New Methods to be created for each type of CMS we offer. 
  // ==================================================================
  getDigitalForensicsTeamMembers() : Observable<any> {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.get(
      this.url + '/api/UserManagement/TeamManagementData',
      httpPostOptions
    );
  }

  // ==================================================================
  // NOTE: New Methods to be created for each type of CMS we offer. 
  // ==================================================================
  getVideoForensicsTeamMembers()  : Observable<any>{
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.get(
      this.url + '/api/UserManagement/TeamManagementData',
      httpPostOptions
    );
  }

  // ==================================================================
  // NOTE: New Methods to be created for each type of CMS we offer. 
  // ==================================================================
  getPrivateInvestigatorsTeamMembers() {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.http.get(
      this.url + '/api/UserManagement/TeamManagementData',
      httpPostOptions
    );
  }






}
