<!-- Loading GIF Panel -->
<div #loadingDiv class="loadingDiv" *ngIf="isInitialPageLoad">
  <img src="/assets/images/Forensic_Notes_Logo.png" class="loadingLogo" tooltip="Loading Forensic Notes" />
  <img src="/assets/images/loading-gif-png-5.gif" class="loadingAppGif" tooltip="Loading Forensic Notes" />
</div>

<div class="content-wrapper" [ngClass]="{'hidden' : isInitialPageLoad}">
  <h1>Notifications</h1>
      <p class="sectionInfo">Send a notification to all Team members.</p>
  <div class="twoCol">
    <div class="colOne">
      <select #ddNotification id="ddNotification" >
        <option *ngFor="let notifications of allTeamNotificationCategories; let i = index"
          [value]="allTeamNotificationCategories[i].notificationCategoryId">
          {{allTeamNotificationCategories[i].label}}
        </option>
      </select>
      <!-- <div *ngFor="let notifications of allTeamNotificationCategories; let i = index">
      {{allTeamNotificationCategories[i].label}}<br>
      </div>

      <div class="row" *ngFor='let notificationCat of allTeamNotificationCategories'>
        <ejs-radiobutton [label]='notificationCat.label' (click)="clickNotificationType(notificationCat.notificationId)" [value]="notificationCat.notificationId" 
          name="notificationCat.label" [(ngModel)]="notificationType" color="primary"></ejs-radiobutton>
    </div> -->
      <br>
      <br>
      <editor #createNotification [(ngModel)]="notificationText" [init]="{
       base_url: '/tinymce',
       suffix: '.min',
       paste_data_images: true,
       paste_as_text: true,
       imagetools_cors_hosts: ['picsum.photos'],
       paste_webkit_styles: 'all',
        menubar: '',
       toolbar: [
        'undo redo | bold forecolor | numlist bullist table'
       ],
       plugins: [
         'paste importcss image table charmap hr advlist lists wordcount imagetools textpattern quickbars'
       ],
       textpattern_patterns: [
      {start: '*', end: '*', format: 'italic'},
      {start: '**', end: '**', format: 'bold'},
      ],
      toolbar_sticky: false,
      image_advtab: true,
      height: 200,
      image_caption: true,
      quickbars_selection_toolbar: 'bold italic | h2 h3 blockquote | quickimage | inserttable',
      quickbars_insert_toolbar: 'quickimage quicktable | charmap hr | insertdatetime',
      toolbar_mode: 'floating',
      contextmenu: 'image imagetools table inserttable insertdatetime quickimage',
      content_style: [
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' + 
      'img {max-width: 100%;}'
      ]
     
     }"></editor>

     <div class="buttonSectionOneCol">
      <button ejs-button class="success" (click)="sendNotification(ddNotification.value)">
        Send Notification to Team Members
      </button>
    </div>
    </div>
    <!-- 2nd COL -->
    <div class="colTwoInfo">
      <h2>Notifications:</h2>
      <p>
        Notifications are short & concise announcements to all team members.
      </p>
     

    </div>
  </div>
</div>



<!-- Table of Notification -->
<div class="white-box allPersonalTemplate-table">
  <div class="filterSection">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" class="inputEffect">
    </mat-form-field>
  </div>
  <table class="col-md-12" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width:100%;">

    <ng-container matColumnDef="notificationID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> Notification ID </th>
      <td mat-cell *matCellDef="let allTeamNotification" [style.display]="'none'">
        {{allTeamNotification.notificationID}}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="firstCol"><b>Type</b></th>
      <td mat-cell *matCellDef="let allTeamNotification" class="firstCol">{{allTeamNotification.title}}</td>
    </ng-container>
    <ng-container matColumnDef="notificationMessage">

      <th mat-header-cell *matHeaderCellDef mat-sort-header class="messageCol"><b>Message</b></th>
      <!-- <td mat-cell *matCellDef="let allTeamNotification">{{stripHtml(allTeamNotification.notificationMessage)}}</td> -->
      <td mat-cell *matCellDef="let allTeamNotification" class="messageCol">
        <div [innerHTML]="allTeamNotification.notificationMessage"></div>
      </td>
    </ng-container>
    <ng-container matColumnDef="categoryID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> Category ID </th>
      <td mat-cell *matCellDef="let allTeamNotification" [style.display]="'none'">{{allTeamNotification.categoryID}}
      </td>
    </ng-container>
    <ng-container matColumnDef="dateNotificationCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.display]="'none'"> Date Created </th>
      <td mat-cell *matCellDef="let allTeamNotification" [style.display]="'none'">
        {{allTeamNotification.dateNotificationCreated}}</td>
    </ng-container>
    <!-- actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="deleteCol">&nbsp;</th>
      <td mat-cell *matCellDef="let row; let i=index;" class="deleteCol">
        <button mat-icon-button color="accent"
          (click)="deleteNotification(i, row.notificationID, row.title, row.notificationMessage, row.categoryID,row.dateNotificationCreated)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- zIndex of popup shows BEHIND the dialog - Add back once this is fixed -->
  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->


</div>

<!-- Dialog - Delete Notification -->
<ejs-dialog id='notificationDelete' header='Delete Notification?' showCloseIcon='true' #ejDialogNotificationDelete
  isModal='true' width="400px" (overlayClick)="onOverlayClickNotificationDelete()" [visible]='false'>
  <p>Are you sure you want to delete this notification?</p>
  <div style="display: inline-grid;">
    <p><span class="subheading">Type :</span> {{Title}}</p>
    <p><span class="subheading">Message :</span></p>
    <div [innerHTML]="NotificationMessage"></div>
  </div>
  <div style="text-align: center;">
    <button ejs-button (click)="NoDelete()" class="cancel">No</button>
    <button ejs-button (click)="YesDeleteNotification()" class="success">Yes</button>
  </div>
</ejs-dialog>