import { ExhibitType } from "../customObjects/ExhibitType";

export default class ExhibitHelper {




    constructor(
    ) {
        // Default Constructor
    }

    static fixDisplayByReplacingExhibitTypeWithProperName(currDisplayString: string, exhibitTypeStringToReplace: string,
        exhibitType: ExhibitType): string {

            let properName: string = ExhibitHelper.getExhibitDisplayNameFromExhibitType(exhibitType);
            console.log("labelText", properName);

            let newDisplayString = currDisplayString.replace(exhibitTypeStringToReplace, properName);

            return newDisplayString;
    }



    static getExhibitDisplayNameFromExhibitType(exhibitType: ExhibitType): string {

        console.log("getExhibitDisplayNameFromExhibitType-exhibitType", exhibitType);

        // ------------------------------------------------------------------------------------------------------------
        // NOTE: If the following is NOT working, then perhaps you are passing
        // in a string representation of the ExhibitType. Although it seems like
        // it should work, you need to 'convert' prior using...
        // let exhibitType: ExhibitType = ExhibitModel.ConvertStringToExhibitTypeObject(e.derived["exhibitType"]);
        // ------------------------------------------------------------------------------------------------------------


        switch (exhibitType) {
            case ExhibitType.Computer:        // Generic for Laptop and Desktop
                return "Computer";
                break;
            case ExhibitType.Laptop:
                return "Laptop";
                break;
            case ExhibitType.Desktop:
                return "Desktop";
                break;
            case ExhibitType.Cell:
                return "Mobile Device";
                break;
            case ExhibitType.Cloud:
                return "Cloud Data";
                break;
            case ExhibitType.HDD:
                return "HDD/SSD";
                break;
            case ExhibitType.Other:
                return "Other";
                break;
            case ExhibitType.SD:
            case ExhibitType.VideoSD:
                return "SD Card";
                break;
            case ExhibitType.USB:
                return "USB Flash Drive";
                break;
            case ExhibitType.SIM:
                return "SIM Card";
                break;
            case ExhibitType.Vehicle:
                return "Vehicle";
                break;
            case ExhibitType.NAS:
                return "NAS";
                break;
            case ExhibitType.VideoOther:
                return "Other";
            case ExhibitType.VideoDVR:
                return "DVR";
            case ExhibitType.VideoElectronicFile:
                return "Electronic File Transfer";
            case ExhibitType.VideoHDD:
                return "HDD/SSD";
            case ExhibitType.VideoOpticalDisk:
                return "Optical Disk";
            case ExhibitType.VideoSD:
                return "SD Card";
            case ExhibitType.VideoTape:
                return "Tape";
            case ExhibitType.VideoUSB:
                return "USB Flash Drive";
            default:
                console.error("Unknown Exhibit Type to Label : ", exhibitType);
                return "unknown";
                break;
        }
    }

}