import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-invited-member',
  templateUrl: './invited-member.component.html',
  styleUrls: ['./invited-member.component.css'],
})
export class InvitedMemberComponent implements OnInit {
  public form: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) {

    document.getElementById('side-1').style.display = 'none';
    document.getElementById('main-content').style.width = '100%';
    this.form = new UntypedFormGroup({
      invitee: new UntypedFormArray([]),
    });
    this.addInvitee();
  }

  ngOnInit(): void {}

  addInvitee() {
    let control = <UntypedFormArray>this.form.controls['invitee'];
    control.push(
      new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
      })
    );
  }

  get inviteeArrayControl(): AbstractControl[] {
    let control = this.form.controls['invitee'] as UntypedFormArray;
    return control.controls;
  }
  public removeOrClearEmail(i: number) {
    const invitee = this.form.get('invitee') as UntypedFormArray;
    if (invitee.length > 1) {
      invitee.removeAt(i);
    } else {
      invitee.reset();
    }
  }
  submit(form) {}
}
