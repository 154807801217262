<!-- Loading GIF Panel -->
<div #loadingDiv class="loadingDiv" *ngIf="isInitialPageLoad">
  <img src="/assets/images/Forensic_Notes_Logo.png" class="loadingLogo" tooltip="Loading Forensic Notes" />
  <img src="/assets/images/loading-gif-png-5.gif" class="loadingAppGif" tooltip="Loading Forensic Notes" />
</div>

<div [ngClass]="{'hidden' : isInitialPageLoad}">
  <div class="content-wrapper">
    <h1>
      Team Management
    </h1>
    <p class="sectionInfo">Manage your team.</p>

    <div id="divTeamContent">
      <!-- if portrait on phone then rotate -->
      <div class="buttonRight">
        <button ejs-button cssClass="e-success" type="button" class="success" (click)="onCreate()">
          Add Team Member
        </button>
      </div>
      <ejs-checkbox label="Hide Disabled Users" (change)="changeHandler($event)" [checked]="cbDisabledUser">
      </ejs-checkbox>
      <!-- Table of Team Members -->
      <div class="white-box allPersonalTemplate-table">
        <table mat-table [dataSource]="dataSource" matSort class="col-md-12 mat-elevation-z8" style="width:100%;">
          <!-- Name Column -->
          <ng-container matColumnDef="legalName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row">
              <div class="details">
                <span class="e-avatar e-avatar-small e-avatar-circle blue" *ngIf="row.profileImage ==''"
                  style="margin-right: 5px;">
                  {{row.legalName.split(" ")[0].charAt(0)}}{{row.legalName.split(" ")[1] && row.legalName.split("
                  ")[1].charAt(0)}}
                </span>
                <div class="e-avatar e-avatar-circle e-avatar-small" style="margin-right: 5px;"
                  *ngIf="row.profileImage !=''">
                  <img [src]="row.profileImage | safe: 'resourceUrl'" />
                </div>
                <div class="avatar-name">
                  {{row.displayName}}
                  <div>
                    {{row.email}}
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- User Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="!row.isUser">Disabled</span>
              <span *ngIf="row.isUser && !row.isAdminUser">Member</span>
              <span *ngIf="row.isUser && row.isAdminUser">Admin</span>
            </td>
          </ng-container>

          <!-- Last Activity Column -->
          <ng-container matColumnDef="lastLoggedInUTC">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Activity </th>
            <td mat-cell *matCellDef="let row"> {{UTCtoLocalTime(row.lastLoggedInUTC)}} </td>
          </ng-container>

          <!-- Menu Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" style="text-align: right;">
              <button mat-icon-button (click)="showMenu(row)" aria-label="More Options Menu">
                <mat-icon>more_vert</mat-icon>
              </button>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.isUser ? '' : 'disabledRow'"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
          <!-- zIndex of popup shows BEHIND the dialog - Add back once this is fixed -->
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
      </div>
    </div>
  </div>
  <div id="divRotateMsg" style="display: none;">
    <p>Please rotate phone to Landscape mode to use this feature</p>
    <br />
    <img src="/assets/images/rotate-phone.png" />
  </div>

</div>

<!-- User Menu -->
<ejs-dialog id="ejDialogUserMenu" #ejDialogUserMenu isModal="true" (overlayClick)="onOverlayClickOutsideMenu()"
  showCloseIcon="true" width="400px" [visible]="false" header="Manage User Options" [animationSettings]='animationSettings'
  [enableResize]='false' showCloseIcon='true' [target]="dialogDragTarget"  [allowDragging]='allowDialogDragging'>
  <ng-template #content>
    <!-- Profile Image -->
    <div class="menuAvatar">
      <span class="e-avatar e-avatar-circle blue  e-avatar-large" *ngIf="profileImageCurrUser ==''"
        style="margin-right: 5px;">
        {{legalNameCurrUser.split(" ")[0].charAt(0)}}{{legalNameCurrUser.split(" ")[1] && legalNameCurrUser.split("
        ")[1].charAt(0)}}
      </span>
      <div class="e-avatar e-avatar-circle e-avatar-large" style="margin-right: 5px;" *ngIf="profileImageCurrUser !=''">
        <img [src]="profileImageCurrUser | safe: 'resourceUrl'" />
      </div>
    </div>
    <span class="heading">Legal Name:</span>{{legalNameCurrUser}}<br>
    <span class="heading">Display Name:</span>{{displayNameCurrUser}}<br>
    <span class="heading">Email:</span>{{emailCurrUser}}<br>
    <hr>
    <button mat-button class="mat-icon-menu-button" [title]="" *ngIf="isAdminUser"
      (click)="onUserAsMember(userIDCurrUser)">
      <mat-icon>person</mat-icon>Change to Regular Member
    </button>
    <button mat-button class="mat-icon-menu-button" [title]="" *ngIf="isAdminUser == false && isUser"
      (click)="onUserAsAdmin(userIDCurrUser)">
      <mat-icon>manage_accounts</mat-icon>Change to Admin Member
    </button>
    <button mat-button class="mat-icon-menu-button" [title]="" *ngIf="isUser" (click)="onResetPassword(userIDCurrUser)">
      <mat-icon>lock_reset</mat-icon>Reset Password
    </button>
    <button mat-button class="mat-icon-menu-button" [title]="" *ngIf="isUser" (click)="onChangePhone(userIDCurrUser)">
      <mat-icon>phone</mat-icon>Change MFA phone #
    </button>
    <button mat-button class="mat-icon-menu-button" [title]="" *ngIf="isUser"
      (click)="onUserAsDisabled(userIDCurrUser)">
      <mat-icon>person_off</mat-icon>Disable User
    </button>
    <button mat-button class="mat-icon-menu-button" [title]="" *ngIf="isUser == false"
      (click)="onUserEnable(userIDCurrUser)">
      <mat-icon>person</mat-icon>Enable User
    </button>
  </ng-template>
</ejs-dialog>

<!-- DIALOG - Regular User? -->
<ejs-dialog id='ejDialogMember' #ejDialogMember header="Confirm" width='400px' [buttons]='buttons' [visible]="false"
  isModal="true" [animationSettings]='animationSettings'>
  <p>Make this user an Regular User?</p>
  <br>
  <p>NOTE: User will only be able to view their own notebooks.</p>
</ejs-dialog>

<!-- DIALOG - Admin User? -->
<ejs-dialog id='ejDialogAdmin' #ejDialogAdmin header="Confirm" width='400px' [buttons]='adminModalButtons'
  [visible]="false" isModal="true" [animationSettings]='animationSettings'>
  <p>Make this user an Admin?</p>
  <br>
  <p>NOTE: User will be able to view all notebooks and notes in account.</p>
</ejs-dialog>

<!-- Add User -->
<ejs-dialog id='ejDialogCreate' #ejDialogCreate showCloseIcon='true' header='Add Team Member' isModal="true"
  width='600px' [visible]="false">
  <form [formGroup]="form" (ngSubmit)="onAddTeamMember(form)" novalidate>
    <mat-form-field appearance="outline">
      <mat-label>Full Legal Name</mat-label>
      <input #name matInput placeholder="Enter Full Legal Name..." minlength="1" maxlength="50"
        formControlName="name" />
      <mat-error *ngIf="name.invalid">required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input #email matInput placeholder="Enter Email..." minlength="1" maxlength="50" formControlName="email" />
      <mat-error *ngIf="email.invalid">required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="phoneNumber">
      <mat-label>Cell Phone Number</mat-label>
      <input (focus)="isChangeCellLabel()" (focusout)="isChangeCellLabelHide($event)" #phone matInput ng2TelInput
        [ng2TelInputOptions]="{ initialCountry: 'us' }" formControlName="phone" placeholder="eg: (604) 555-1234..."
        #txtTelephone id="phone" onkeypress="return event.charCode >= 48 && event.charCode <=
                                          57" onkeyup="var v= this.value;if (this.value.length === 3)
                                          {this.value= '('+v+')' + '-';}" (countryChange)="onCountryChange($event)" /> 
      <mat-error *ngIf="phone.invalid">required</mat-error>
    </mat-form-field>

    <div class="buttonSectionTwoCol">
      <button ejs-button class="cancel" type="button" (click)="hideDialogCreate()">
        Cancel
      </button>
      <button ejs-button type="submit" class="success">
        <img src="assets/images/project.gif" class="button-spinner" *ngIf="loading" />
        Create User & Send Login Information
      </button>
    </div>
  </form>
</ejs-dialog>

<!-- Reset Password -->
<ejs-dialog id='ejDialogResetPassword' #ejDialogResetPassword header="Reset Password" width='500px' isModal="true"
  [buttons]='ResetPasswordModalButtons' [visible]="false">
  Are you sure you want to reset the users password?
</ejs-dialog>


<!-- DIALOG - Change Phone Number-->
<ejs-dialog id='ejDialogChangePhoneNumber' #ejDialogChangePhoneNumber showCloseIcon='true'
  header='Change MFA Phone Number' isModal="true" width='600px' [visible]="false">
  <form [formGroup]="editPhoneForm" (ngSubmit)="onChangeSubmit(editPhoneForm)" novalidate>
    <p>Enter new mobile cell number</p>
    <mat-form-field appearance="outline" class="phoneNumber">
      <mat-label>Cell Phone Number</mat-label>
      <input (focus)="isChangeCellLabel()" class="inputEffect" (focusout)="isChangeCellLabelHide($event)" matInput
      ng2TelInput [ng2TelInputOptions]="{ initialCountry: 'us' }" formControlName="phone" #txtTelephone
      id="txtTelephone" onkeypress="return event.charCode >= 48 && event.charCode <=
                                        57" onkeyup="var v= this.value;if (this.value.length === 3)
                                        {this.value= '('+v+')' + '-';}" (countryChange)="onCountryChange($event)" />
      <mat-error *ngIf="phone.invalid">required</mat-error>
    </mat-form-field>



    <!-- <h2>Cell Number</h2>
    <input (focus)="isChangeCellLabel()" class="inputEffect" (focusout)="isChangeCellLabelHide($event)" matInput
      ng2TelInput [ng2TelInputOptions]="{ initialCountry: 'us' }" formControlName="phone" #txtTelephone
      id="txtTelephone" onkeypress="return event.charCode >= 48 && event.charCode <=
                                        57" onkeyup="var v= this.value;if (this.value.length === 3)
                                        {this.value= '('+v+')' + '-';}" (countryChange)="onCountryChange($event)" /> -->
    <div class="buttonSectionTwoCol">
      <button ejs-button class="cancel" type="button" (click)="hideDialogUpdatePhone()">
        Cancel
      </button>
      <button ejs-button  type="submit" class="success">
        <img src="assets/images/project.gif" class="button-spinner" *ngIf="loading" />
        Change MFA Phone Number
      </button>
    </div>
  </form>
</ejs-dialog>