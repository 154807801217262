import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientEncryptionPasswordService } from 'src/app/service/client-encryption-password.service';
import { IndexDBService } from 'src/app/service/indexDB/index-db.service';
import { UserProfileService } from 'src/app/service/user-profile.service';

@Component({
  selector: 'app-encryption-password',
  templateUrl: './encryption-password.component.html',
  styleUrls: ['./encryption-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncryptionPasswordComponent {
  public hidePassword = true;
  public readonly animationSettings: Object = {
    effect: 'Zoom',
    duration: 600
  };
  isVisible = false;
  allowClosing = false;
  passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
  isPasswordSetup = false;
  state: 'FillPassword' | 'DeleteConfirmation' = 'FillPassword';
  constructor(
    private clientEncryptionPasswordService: ClientEncryptionPasswordService,
    private indexDBService: IndexDBService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private userProfileService: UserProfileService,
  ){
    this.isPasswordSetup = !!this.userProfileService.isCachedUser();
  }

  // ===================================
  // SHOW
  // ===================================
  show() {
    this.isVisible = true;
    this.hidePassword = true;
    this.allowClosing = false;
    this.cd.detectChanges();
  }

  // ===================================
  // HIDE
  // ===================================
  hide() {
    this.isVisible = false;
    this.allowClosing = true;
    this.cd.detectChanges();
  }

  validation(event: any) {
    if(!this.allowClosing) {
      event.cancel = true;
    }
  }

  onSavePassword() {
    const password = this.passwordControl.getRawValue();
    this.passwordControl.reset();
    const success = this.clientEncryptionPasswordService.setCustomKEK(password);
    if(success) {
      this.hide();
      const successMessage = this.isPasswordSetup ? 'Password Confirmed' : 'Password has been setup successfully';
      this.toastr.success(successMessage);
      this.cd.detectChanges();
      return
    }
    this.state = 'DeleteConfirmation';
    this.cd.detectChanges();
  }

  onDeleteCancel() {
    this.state = 'FillPassword';
    this.cd.detectChanges();
  }

  onDeleteOldEncriptionPassword() {
    this.clientEncryptionPasswordService.clearCustomKEK();
    this.indexDBService.DeleteAllDatabases();
    this.toastr.success("Client Data Deleted");
    this.state = 'FillPassword';
    this.cd.detectChanges();
  }
}
