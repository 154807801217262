import { Injectable } from '@angular/core';
import { fromEvent, map, merge, of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkConnectionService {
  isOnline: boolean = false;
  networkStatus$: Observable<boolean>;

  constructor() {
    this.checkNetworkStatus();
    this.networkStatus$.subscribe((status) => {
      this.isOnline = status;
    })
  }

  checkNetworkStatus() {
    this.isOnline = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => !!navigator.onLine));
     
  }
}
