export default class URLUtils {


    static setURLCachingParam(theURL: string, enableServiceWorkerCache: boolean, userID: string){
      // ------------------------------------------------------
      // UserID added so that Cache get by uniquely separated.
      // NOT needed if bypass=true
      // ------------------------------------------------------

      let disableServiceWorkerCache: boolean = !enableServiceWorkerCache;

      // Do NOT Cache Any API's for NOW!
      console.log("API's NOT CACHED");
      disableServiceWorkerCache = true;

        // if(enableServiceWorkerCache, userID){
          // Check if URL already contains Params
          if(theURL.includes("?")){
            // other params exist
            theURL = theURL + "&ngsw-bypass=" + disableServiceWorkerCache + "&userID=" + userID;
          }
          else{
            // No other params
            theURL = theURL + "?ngsw-bypass=" + disableServiceWorkerCache + "&userID=" + userID;
    
          }
        // }
        // else{
        //   // Allow Cach
        //   if(theURL.includes("?")){
        //     // other params exist
        //     theURL = theURL + "&ngsw-bypass=true";
        //   }
        //   else{
        //     // No other params
        //     theURL = theURL + "?ngsw-bypass=true";
    
        //   }
        // }
        
        console.log("setURLCachingParam(...)", theURL);
        return theURL;
      }


}