import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NetworkConnectionService } from './network-connection.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private networkConnectionService: NetworkConnectionService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.networkConnectionService.isOnline) {
      return throwError(() => 'Network is offline');
    }
    return next.handle(req);
  }
}
