import { NumericBlurEventArgs } from '@syncfusion/ej2-inputs';

export class UpdateUserProfileModel
 {
  legalName: string;
  displayName: string;  
  usersEmail: string;
  timeZone: string;
  agreeToTerms: boolean;
  passwordToProtectDownloadedFiles: string;
  accountPurchaseStatus: string;
  notebookPageSize: number;
  loginMessage: string;
  isGroupAdmin: boolean;
  isGlobalAdmin: boolean;
  result: any;
  alwaysStayOnCurrentNoteOnTimestamp: boolean;
  editorToolbarMode: number;
  timeStampShortCut: number;
  numberOfUnreadAlerts:number;
  profileImage:string;
}
