import { Inject, Injectable } from "@angular/core";
import {
    LOCAL_STORAGE,
} from "@ng-web-apis/common";


@Injectable({
    providedIn: "root",
})

export default class SecurityUtils {

    private key_enableServiceWorkerCache = "enableServiceWorkerCache";
    // localStorage: Storage;

    constructor(
        @Inject(LOCAL_STORAGE) storage: Storage,

    ) {

        console.log("SecurityUtils - constructor()");

        // this.localStorage = localStorage;


        // ----------------------------------------------
        // CACHE SENSITIVE INFORMATION?
        // Notebooks and Notes?
        // User decides based on security of device
        // ----------------------------------------------
        if (storage) {
            if (storage.getItem(this.key_enableServiceWorkerCache)) {
                // Key exists.. Do nothing.
                console.log("SecurityUtils - Key exists in LocalStorage", storage.getItem(this.key_enableServiceWorkerCache));

            }
            else {
                // set Default = True - Caching Sensitive Info OFF
                storage.setItem(this.key_enableServiceWorkerCache, "true");
                console.log("SecurityUtils - Setting Key");
            }
        }

    }

    // ==========================================================
    // Service Worker Cache Disabled?
    // ==========================================================
    isServiceWorkerCacheEnabled(): boolean {
        
        if (localStorage) {
            let enableServiceWorkerCache: boolean = false;          // Default - False DISABLED
            
            if (localStorage.getItem(this.key_enableServiceWorkerCache)) {
                enableServiceWorkerCache = localStorage.getItem(this.key_enableServiceWorkerCache).toLocaleLowerCase() == "false";
            }
            // console.log("isServiceWorkerCacheEnabled()", enableServiceWorkerCache, userID);
            return enableServiceWorkerCache;
        }
        return false;
    }

    turnOnOfflineCapabilties() {
        localStorage.setItem(this.key_enableServiceWorkerCache, "false");
    }

    turnOffOfflineCapabilties() {
        localStorage.setItem(this.key_enableServiceWorkerCache, "true");
    }

}