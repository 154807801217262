import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ForensicTeamManagementService } from './../../../service/forensic-team-management.service';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { throwIfEmpty } from 'rxjs/operators';
import ErrorHandling from 'src/app/shared/Utility/ErrorHandling';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-team-setting',
  templateUrl: './team-setting.component.html',
  styleUrls: ['./team-setting.component.css'],
})
export class TeamSettingComponent implements OnInit, AfterViewInit {
  public data: object[];
  public invitedUser: object[];
  public pageSettings: PageSettingsModel;

  // Dialog Member
  @ViewChild('ejDialogMember', { static: true })
  ejDialogMember: DialogComponent;
  public targetElement: HTMLElement;

  // Dialog Admin
  @ViewChild('ejDialogAdmin', { static: true })
  ejDialogAdmin: DialogComponent;
  public targetElementAdmin: HTMLElement;
  // Dialog Invited User
  @ViewChild('ejDialogInvitedMember', { static: true })
  ejDialogInvitedMember: DialogComponent;
  public targetElementInvitedMember: HTMLElement;

  @ViewChild('ejDialogCreate', { static: true })
  ejDialogCreate: DialogComponent;
  public targetElementCreate: HTMLElement;

  @ViewChild('ejDialogResetPassword', { static: true })
  ejDialogResetPassword: DialogComponent;
  public targetElementResetPassword: HTMLElement;

  @ViewChild('ejDialogChangePhoneNumber', { static: true })
  ejDialogChangePhoneNumber: DialogComponent;
  public targetElementChangePhoneNumber: HTMLElement;

  @ViewChild('ejDialogUserMenu', { static: true })
  ejDialogUserMenu: DialogComponent;

  public userID;
  public isActive = false;
  public requestID;
  public isInvitedActive = false;
  @ViewChild('insideElement') insideElement;

  // ------------------------------------------------------------------
  // class name of Div tag where Dialogs can be dragged around in.
  // Found in the app.component.html file.
  // ------------------------------------------------------------------
  public allowDialogDragging: Boolean = true;
  public dialogDragTarget = '.setting-page';

  displayedColumns: string[] = [
    'legalName',
    'type',
    'lastLoggedInUTC',
    'action',
  ];
  dataSource: MatTableDataSource<any>;

  invitedUserDisplayedColumns: string[] = [
    'name',
    'status',
    'invited',
    'accepted',
    'action',
  ];
  invitedUserSource: MatTableDataSource<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  form: UntypedFormGroup;
  editPhoneForm: UntypedFormGroup;

  prePhone = '1';
  cellPhoneTextActive = false;
  loading = false;
  cbDisabledUser = false;

  isInitialPageLoad: boolean = true;


  public screenWidth: any = 1920;
  public screenHeight: any = 1080;

  public animationSettings: Object = { effect: 'Zoom', duration: 400 };

  constructor(
    private forensicTeamManagementService: ForensicTeamManagementService,
    private router: ActivatedRoute,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    public errorHandling: ErrorHandling,
    private appComponent: AppComponent
  ) {
    this.pageSettings = { pageSize: 10 };
    this.ReLoadGrid();
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
    });
    this.editPhoneForm = this.fb.group({
      phone: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    document.body.classList.add('team');
    document.body.classList.remove('preference');
    document.body.classList.remove('profile');
    document.body.classList.remove('branding');
    document.body.classList.remove('template');
    document.body.classList.remove('forensicWitness');
    document.body.classList.remove('notifications');

    // ----------------------------------------------------------
    // Make sure data shows properly!
    // If not, ask user to increase size of screen or rotate.
    // ----------------------------------------------------------
    this.ScreenSizeSetup();
  }
  get f() {
    return this.form.controls;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.ScreenSizeSetup();
  }

  ScreenSizeSetup() {
    // -------------------------------------------
    // Initial Page Load - This is = 0
    // so use default values in that case
    // -------------------------------------------
    if (window.innerWidth != 0) {
      //console.log('Setting Width and height from window');
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }

    let MinWidth: number = 400;

    //  console.log('WIDTH for Display = ' + this.screenWidth);

    if (this.screenWidth < MinWidth) {
      // console.log('rotate screen');
      document.getElementById('divTeamContent').style.display = 'none';
      document.getElementById('divRotateMsg').style.display = 'block';
    } else {
      document.getElementById('divTeamContent').style.display = 'block';
      document.getElementById('divRotateMsg').style.display = 'none';
    }
  }

  isChangeCellLabel() {
    this.cellPhoneTextActive = true;
  }
  isChangeCellLabelHide(event) {
    let text = (<HTMLInputElement>document.getElementById('txtTelephone'))
      .value;
    if (text == '' || text == null || text || undefined) {
      this.cellPhoneTextActive = false;
    }
    if (text) {
      this.cellPhoneTextActive = true;
    }
  }
  onCountryChange(event) {
    this.prePhone = event.dialCode;
  }

  async ReLoadGrid() {

    // console.log("reloadGrid called...");
    this.forensicTeamManagementService
      .getTeamManagementData()
      .subscribe((res: any) => {
        this.data = res.result.users;

        // console.log("User", this.data);
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort.toArray()[0];

        // Data Loaded, - Show Page.
        this.isInitialPageLoad = false;
      },
        (error) => {
          console.error(error);
          this.toastr.error("Unknown error please contact support", "Error");
        });
  }

  ngAfterViewInit() { }

  public onOverlayClickheadMenu: EmitType<object> = () => {
    this.ejDialogMember.hide();
  };

  moreMenu(event: any, userID) {
    this.userID = userID;
    this.isActive = !this.isActive;
  }
  moreMenuInvitedTable(event: any, requestID) {
    this.requestID = requestID;
    this.isInvitedActive = !this.isInvitedActive;
  }





  // ===========================================
  // CHANGE USER TO REGULAR - OPEN DIALOG
  // ===========================================
  onUserAsMember(userID: string) {
    //console.log('user to member', userID);
    this.userID = userID;
    this.isActive = false;
    this.ejDialogMember.show();
  }

  // ============================
  // CHANGE USER TO REGULAR
  // ============================
  async onChangeUserAsMember() {
    await this.forensicTeamManagementService
      .removeUserAsAdmin(this.userID)
      .subscribe((res: any) => {
        //console.log(res);


        if (res.success == true) {
          // --------------
          // SUCCESS
          // --------------
          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.success(
            '<p>Selected user is now a Regular User.</p>',
            'Downgraded to Regular User',
            { enableHtml: true }
          );

          this.ejDialogAdmin.hide();
          this.ReLoadGrid();

          this.isAdminUser = false;

        }
        else {
          // ------------
          // FAILED
          // ------------
          this.toastr.error("User NOT Updated - Error");
          this.ejDialogAdmin.hide();
        }


        this.ejDialogMember.hide();
      },
        (err) => {
          console.error(err);
          this.loading = false; // Spin button

          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.error(
            err.error.errorMessage,
            err.error.errorType,
            { enableHtml: true }
          );

          this.ejDialogMember.hide();
        });


  }



  // =====================================
  // CHANGE USER TO ADMIN - OPEN DIALOG
  // =====================================
  onUserAsAdmin(userID: string) {
    //console.log('user to admin', userID);
    this.isActive = false;
    this.userID = userID;
    this.ejDialogAdmin.show();
  }


  // ===============================
  // CHANGE USER TO ADMIN
  // ===============================
  async onChangeUserAsAdmin() {
    //console.log(this.userID);
    await this.forensicTeamManagementService
      .addUserAsAdmin(this.userID)
      .subscribe((res: any) => {
        //console.log(res);

        if (res.success == true) {
          // --------------
          // SUCCESS
          // --------------
          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.success(
            '<p>Selected user is now an Admin with access to all notes and notebooks.</p>',
            'Upgraded to Admin',
            { enableHtml: true }
          );

          this.ejDialogAdmin.hide();
          this.ReLoadGrid();

          this.isAdminUser = true;

        }
        else {
          // ------------
          // FAILED
          // ------------
          this.toastr.error("User NOT Updated - Error");
        }
      },
        (err) => {
          console.error(err);
          this.loading = false; // Spin button

          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.error(
            err.error.errorMessage,
            err.error.errorType,
            { enableHtml: true }
          );
        });


  }


  // ================
  // DISABLE USER
  // ================
  onUserAsDisabled(userID: string) {
    //console.log(userID), (this.isActive = false);
    this.userID = userID;
    this.isActive = false;

    this.forensicTeamManagementService.disableEnableUser(userID, false).subscribe((res: any) => {
      if (res.success == true) {
        // --------------
        // SUCCESS
        // --------------
        this.toastr.toastrConfig.enableHtml = true;
        this.toastr.success(
          '<p>Selected user has been disabled.</p>',
          'Disabled User',
          { enableHtml: true }
        );

        this.ejDialogAdmin.hide();
        this.ReLoadGrid();

        this.isUser = false;


      }
      else {
        // ------------
        // FAILED
        // ------------
        this.toastr.error("User NOT Updated - Error");
      }
    },
      (err) => {
        console.error(err);
        this.loading = false; // Spin button

        this.toastr.toastrConfig.enableHtml = true;
        this.toastr.error(
          err.error.errorMessage,
          err.error.errorType,
          { enableHtml: true }
        );
      });
  }

  // ================
  // ENABLE USER
  // ================
  onUserEnable(userID: string) {

    //console.log("ENABLE")
    this.userID = userID;
    this.isActive = false;


    this.forensicTeamManagementService.disableEnableUser(userID, true).subscribe((res: any) => {
      if (res.success == true) {
        // --------------
        // SUCCESS
        // --------------
        this.toastr.toastrConfig.enableHtml = true;
        this.toastr.success(
          '<p>Selected user has been enabled.</p>',
          'Enabled User',
          { enableHtml: true }
        );

        this.ejDialogAdmin.hide();
        this.ReLoadGrid();

        this.isUser = true;

      }
      else {
        // ------------
        // FAILED
        // ------------
        this.toastr.error("User NOT Updated - Error");
      }
    },
      (err) => {
        console.error(err);
        this.loading = false; // Spin button

        this.toastr.toastrConfig.enableHtml = true;
        this.toastr.error(
          err.error.errorMessage,
          err.error.errorType,
          { enableHtml: true }
        );
      });

  }


  // =============================
  // RESET PASSWORD TO TEMP
  // =============================
  onChangeResetPassword() {
    this.isActive = false;
    this.forensicTeamManagementService
      .resetPasswordToTemp(this.userID)
      .subscribe((res: any) => {

        //console.log(res);

        if (res.success == true) {
          // --------------
          // SUCCESS
          // --------------
          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.success(
            '<p>A temporary password was sent via SMS to the owner.</p>',
            'Password Reset',
            { enableHtml: true }
          );
          this.ejDialogResetPassword.hide();


        }
        else {
          // ------------
          // FAILED
          // ------------
          this.toastr.error("Password NOT Reset - Error");
          this.ejDialogResetPassword.hide();

        }

      },
        (err) => {
          console.error(err);
          this.loading = false; // Spin button

          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.error(
            err.error.errorMessage,
            err.error.errorType,
            { enableHtml: true }
          );
          this.ejDialogResetPassword.hide();
        }

      );
  }

  public hideDialog: EmitType<object> = () => {
    this.ejDialogMember.hide();
  };
  public hideAdminDialog: EmitType<object> = () => {
    this.ejDialogAdmin.hide();
  };

  public hideResetPasswordDialog: EmitType<object> = () => {
    this.isActive = false;
    this.ejDialogResetPassword.hide();
  };
  public buttons: Object = [
    {
      click: this.onChangeUserAsMember.bind(this),
      buttonModel: {
        content: 'OK',
        isPrimary: true,
      },
    },
    {
      click: this.hideDialog.bind(this),
      buttonModel: {
        content: 'Cancel',
      },
    },
  ];
  public adminModalButtons: Object = [
    {
      click: this.onChangeUserAsAdmin.bind(this),
      buttonModel: {
        content: 'OK',
        isPrimary: true,
      },
    },
    {
      click: this.hideAdminDialog.bind(this),
      buttonModel: {
        content: 'Cancel',
      },
    },
  ];

  public ResetPasswordModalButtons: Object = [
    {
      click: this.onChangeResetPassword.bind(this),
      buttonModel: {
        content: 'OK',
        isPrimary: true,
      },
    },
    {
      click: this.hideResetPasswordDialog.bind(this),
      buttonModel: {
        content: 'Cancel',
      },
    },
  ];


  onPopupInvitedMember() {
    var windowFeatures =
      'scrollbars=yes,status=yes,resizable=yes,top=200,left=500,width=900,height=600';
    const url = window.location.origin + '/setting/invite-member/';

    window.open(url, '_blank', windowFeatures);
    // this.ejDialogInvitedMember.show();
  }

  UTCtoLocalTime(date) {
    var comparisionDate = new Date(date);
    var defaultDate = new Date('01/01/1901 00:00:00');
    if (comparisionDate < defaultDate) {
      return '---';
    } else {
      return new Date(date).toLocaleString();
    }
  }

  onCreate() {
    this.ejDialogCreate.show();
  }


  // ==============================
  // CREATE USER
  // ==============================
  onAddTeamMember(form) {

    // console.log("form", form);

    // -----------------------
    // Validate Values
    // -----------------------
    let isValid: boolean = true;
    if (!form.value.name) {
      this.toastr.warning("Name must be provided");
      isValid = false;
    }

    if (!form.value.email) {
      this.toastr.warning("Email must be provided");
      isValid = false;
    }

    if (!form.value.phone) {
      this.toastr.warning("Cellphone Number must be provided");
      isValid = false;
    }


    if (!isValid) {
      return;
    }

    // else - All valid!

    this.loading = true;
    this.forensicTeamManagementService
      .createUser(
        form.value.name,
        form.value.email,
        form.value.phone,
        Number(this.prePhone)
      )
      .subscribe(
        (res: any) => {
          //console.log(res);
          if (res.success) {
            // --------------
            // SUCCESS
            // --------------
            this.loading = false;
            this.toastr.success('User Created');
            this.form.reset();
            this.ejDialogCreate.hide(); 
            this.ReLoadGrid();
          }
        },
        (err) => {
          console.error(err);
          this.loading = false; // Spin button

          if (err.status == 500) {
            // --------------------------
            // 500 SERVER ERROR
            // --------------------------
            console.error("Server Error");
            this.errorHandling.LogErrorToServer("MAJOR ERROR - 500 SERVER from INVITE USER TO TEAM", err);

            this.toastr.error("<p>It looks like our server encountered an unexpected issue. We're really sorry for the inconvenience, but don't worry, our team is on it!</p>" +
            "<p>Contact us at support@forensicnotes.com, and we'll get back to you as soon as possible.</p>", "Oops! Something Went Wrong. ", { enableHtml: true, });

          }
          else {
            this.toastr.error("<p>" + err.error.errorMessage + "</p>", err.error.errorType, { enableHtml: true, });
          }


        }
      );

  }

  // ===========================
  // CHANGE MFA PHONE NUMBER
  // ===========================
  onChangeSubmit(form) {

    // VALIDATION
    if (!form.value.phone) {
      this.toastr.warning("Cell Number must be provided");
      return;
    }

    if (form.value.phone.toString().length < 10) {
      this.toastr.warning("Cell Number does not appear to be valid. If this is an error, please contact support@forensicnotes.com");
      return;
    }

    // console.log("phone", form.value.phone);


    this.loading = true;
    this.forensicTeamManagementService
      .changePhoneNumber(this.userID, form.value.phone, Number(this.prePhone))
      .subscribe(
        (res: any) => {
          //console.log(res);

          if (res.success == true) {
            // --------------
            // SUCCESS
            // --------------
            this.toastr.toastrConfig.enableHtml = true;
            this.toastr.success(
              '<p>The MFA Phone Number for the selected user has been changed.</p>',
              'MFA Phone Number Changed',
              { enableHtml: true }
            );

            this.ejDialogChangePhoneNumber.hide();
            this.loading = false; // Spin button

          }
          else {
            // ------------
            // FAILED
            // ------------
            this.toastr.error("MFA Phone Number NOT Updated - Error");
          }
        },
        (err) => {
          //console.log(err);
          this.loading = false; // Spin button

          this.toastr.toastrConfig.enableHtml = true;
          this.toastr.error(
            err.error.errorMessage,
            err.error.errorType,
            { enableHtml: true }
          );
        }

      );




  }
  hideDialogCreate() {
    this.ejDialogCreate.hide();
    this.form.reset();
  }
  changeHandler(event) {
    this.cbDisabledUser = event.checked;
    if (this.cbDisabledUser) {
      const data = this.data.filter((item: any) => {
        if (item.isUser) {
          return item;
        }
      });
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    } else {
      this.ReLoadGrid();
    }
  }

  onResetPassword(userId) {
    // console.log(userId);
    this.userID = userId;
    this.ejDialogResetPassword.show();
  }
  onChangePhone(userId) {
    //console.log(userId);
    this.userID = userId;
    this.ejDialogChangePhoneNumber.show();
  }
  hideDialogUpdatePhone() {
    this.ejDialogChangePhoneNumber.hide();
  }


  public currSelectedUser: any = null;
  public userIDCurrUser: string = "";
  public profileImageCurrUser: string = "";
  public isUser: boolean = false;
  public isAdminUser: boolean = false;
  public legalNameCurrUser: string = "";
  public displayNameCurrUser: string = "";

  public emailCurrUser: string = "";



  showMenu(user) {

    // console.log("user", user);

    this.currSelectedUser = user;

    this.userIDCurrUser = user.userID;
    this.isUser = user.isUser;
    this.isAdminUser = user.isAdminUser;

    this.profileImageCurrUser = user.profileImage;

    this.legalNameCurrUser = user.legalName;
    this.displayNameCurrUser = user.displayName;
    this.emailCurrUser = user.email;

    // console.log("showing Menu");

    this.ejDialogUserMenu.show();


  }


  public onOverlayClickOutsideMenu: EmitType<object> = () => {
    // console.log("Hiding menu");
    this.ejDialogUserMenu.hide();
  };


}
