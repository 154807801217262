import { ToastrService } from "ngx-toastr";

export default class NumberUtils {


    // =========================================================
    // Only allow Numeric Values for Incident Number
    // =========================================================
    static allowNumericDigitsOnlyOnKeyUp(e, toastr: ToastrService) {
        const charCode = e.which ? e.which : e.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            toastr.info("Only numeric values allowed");
        }


        // Replace non-numeric with numeric when typed.
        if (/\D/g.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, '');
        }

    }

}