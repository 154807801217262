import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SuccessModel } from '../domain/Success';
import { TemplatePreviewModel } from '../domain/TemplatePreview';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { TemplateModel } from '../domain/Template';
import { protectedResources } from '../auth-config';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient
  ) {}

  url = protectedResources.apiBaseUrl.endpoint;

  GetAllPersonalTemplates(): Observable<TemplateModel> {
    return this.httpClient.get<TemplateModel>(
      this.url + '/api/StructureTemplates/User'
    );
  }

  GetAllTeamTemplates(): Observable<TemplateModel> {
    return this.httpClient.get<TemplateModel>(
      this.url + '/api/StructureTemplates/Team'
    );
  }

  DeleteTemplate(templateID: string): Observable<TemplateModel> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' }),
    };
    return this.httpClient.delete<TemplateModel>(
      this.url + '/api/StructureTemplates/' + templateID,
      httpOptions
    );
  }

  PreviewTemplate(templateID: string): Observable<TemplatePreviewModel> {
    return this.httpClient.get<TemplatePreviewModel>(
      this.url + '/api/StructureTemplates/ ' + templateID + '/Preview'
    );
  }

  RenameTemplate(
    templateId: string,
    tempName: string
  ): Observable<SuccessModel> {
    const formData = new FormData();
    formData.append('newName', tempName);

    const httpPostOptions = {
      withCredentials: true,
    };
    return this.httpClient.patch<SuccessModel>(
      this.url + '/api/StructureTemplates/' + templateId + '/Rename',
      formData,
      httpPostOptions
    );
  }

  MoveToTeam(templateId: string): Observable<SuccessModel> {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.httpClient.patch<SuccessModel>(
      this.url + '/api/StructureTemplates/' + templateId + '/MakeIntoTeam',
      httpPostOptions
    );
  }


  // SNIPPETS

  ConvertTemplateToTeam(templateId: string): Observable<SuccessModel> {
    const httpPostOptions = {
      withCredentials: true,
    };
    return this.httpClient.patch<SuccessModel>(
      this.url + '/api/Templates/ConvertTemplateToTeam?templateId=' + templateId,
      httpPostOptions
    );
  }


  SaveTemplate(templateName: string, newTemplate: string, isTeamTemplate: any) {
    const httpPostOptions = {
      withCredentials: true,
    };

    const formData = new FormData();
    formData.append("templateName", templateName);
    formData.append("newTemplate", newTemplate);
    formData.append("isTeamTemplate", isTeamTemplate);

    return this.httpClient.patch(
      this.url +
        '/api/Templates/SaveTemplate',
        formData,
        httpPostOptions
    );

    // return this.httpClient.patch(
    //   this.url +
    //     '/api/Templates/SaveTemplate?templateName=' +
    //           templateName +
    //           '&newTemplate=' +
    //           newTemplate +
    //           '&isTeamTemplate=' +
    //           isTeamTemplate,
    //     formData,
    //     httpPostOptions
    // );
  }


  GetAllTemplates() {
    const httpPostOptions = {
      withCredentials: true,
    };
    var showAllIncludingDeleted = false;
    return this.httpClient.get(
      this.url +
        '/api/Templates/GetTemplates?showAllIncludingDeleted=' +
        showAllIncludingDeleted,
      httpPostOptions
    );
  }

  GetTemplate(templateID) {
    const httpPostOptions = {
      withCredentials: true,
    };

    return this.httpClient.get(
      this.url + '/api/Templates/GetTemplate?templateID=' + templateID,
      httpPostOptions
    );
  }

  HideTemplate(templateID) {
    const httpPostOptions = {
      withCredentials: true,
    };

    return this.httpClient.patch(
      this.url + '/api/Templates/HideTemplate?templateID=' + templateID,
      httpPostOptions
    );
  }
}
