import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Observable, filter, fromEvent, map, switchMap, take } from 'rxjs';

export interface SWMessage {
  type: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class SwMessageService {
  private isReadySrouce = new BehaviorSubject<{isSuccess: boolean, isInit: boolean, error?: any}>({isSuccess: false, isInit: true});
  isReady$ = this.isReadySrouce.asObservable();

  public message$: Observable<SWMessage>;

  worker: ServiceWorker;
  events: Observable<any>;
  constructor(private swUpdate: SwUpdate) { 
    this.setupServiceWorkerEvent();
  }

  setupServiceWorkerEvent() {
      if(this.swUpdate.isEnabled){
        navigator.serviceWorker
        navigator.serviceWorker.ready.then((registration)=> {
          this.worker = registration.active;
          const rawEvents  = fromEvent<MessageEvent>(navigator.serviceWorker, 'message');
          this.message$ = rawEvents.pipe(map((event) => event.data));
          this.isReadySrouce.next({isInit: true, isSuccess: true});
        }).catch((err) => {
          console.error("Can not init Service worker", err);
          this.isReadySrouce.next({isInit: true, isSuccess: false, error: err});
        })
      }else {
        console.error("Service Worker Not enabled");
        this.isReadySrouce.next({isInit: true, isSuccess: false, error: "Service Worker Not enabled"});
      } 
  }

  getImageFromServiceWorker(): Observable<{imageFiles: File[]}> {
    return this.isReadySrouce.pipe(
      filter(({isInit, isSuccess}) => isInit && isSuccess),
      switchMap(() => {
        this.worker.postMessage("GET_SHARE_IMAGE");
        return this.message$.pipe(
          filter((message)=> message && message.type === "share_files"),
          take(1),
          map(({data})=> {
            return data;
          })
        )
      })
    )
  }
}
