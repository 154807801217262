<ejs-dialog #ejDialogSettings [showCloseIcon]="false" [header]="state === 'FillPassword' ? 'Encryption Password': 'Password is not Correct'" [visible]="isVisible"
  [animationSettings]="animationSettings" [enableResize]='false' [isModal]="true" width="500" (beforeClose)="validation($event)">
  <ng-template #content>
    <ng-container *ngIf="state === 'FillPassword'; else DeletePasswordConfirmationTemplate">
      <mat-form-field appearance="outline">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="passwordControl">
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div class="buttonSectionOneColRight">
        <button [disabled]="passwordControl.invalid" class="success" id="btnSubmit" #btnSubmit (click)="onSavePassword()">{{isPasswordSetup ? 'Submit': 'Save'}}</button>
      </div>
    </ng-container>
  </ng-template>
</ejs-dialog>

<ng-template #DeletePasswordConfirmationTemplate>
  <p>Do you want to delete all existing data on device and use newly entered password for new notes? or do you want to try to re-enter password?
  </p>
  <div class="buttonSectionOneColRight">
    <button class="delete" id="btnSubmit" #btnSubmit (click)="onDeleteOldEncriptionPassword()">Delete</button>
    <button ejs-button class="cancel" type="button" (click)="onDeleteCancel()">Re-Enter Password</button>

  </div>
</ng-template>