import { Injectable } from '@angular/core';
import { deleteDB, IDBPDatabase, openDB } from 'idb';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class IndexDBService {

  // appComponent: AppComponent = null;

  DATABASE_NAME: string = "FN-DB";
  OBJECT_STORE_NAME_NOTEBOOKINFO: string = "notebookInfo";
  OBJECT_STORE_NAME_NOTEBOOKLISTINFO: string = "notebookListInfo";
  OBJECT_STORE_NAME_NOTES: string = "Notes";

  OBJECT_STORE_NAME_OFFLINE_NOTEBOOKS: string = "offlineNoteBooks";
  OBJECT_STORE_NAME_OFFLINE_NOTES: string = "offlineNotes";

  indexDB: IDBPDatabase<any> = null;
  db: any = null;

  // =============================================================
  //
  // =============================================================
  constructor(
    private toastr: ToastrService,

  ) {
    console.log("INDEX-DB --> LOADING SERVICE Constructor");
    // --------------------------------------------------------------------
    // NOTE: This Service is setup and called by the APP_INITIALIZER
    // --------------------------------------------------------------------

  }

  // ==============================================================================
  //
  // NOTE: If I try to call this method with await, it never finished.
  // Not sure why, but luckily, I don't need the await when called from the 
  // Offline Settings page.
  // ==============================================================================
  async load() {
    console.log("INDEX-DB --> load() -- Create DB");

    let _this = this;

    try {

      this.db = await openDB(this.DATABASE_NAME, 2, {
        upgrade: (db, oldVersion, newVersion) => {
          console.log("indexBDVersion" , oldVersion, newVersion)
          if(oldVersion < 1) {
            // -------------------------------------------------
            // Create Notebook Information Details Table
            // -------------------------------------------------
            const store1 = db.createObjectStore(this.OBJECT_STORE_NAME_NOTEBOOKINFO, {
              // The 'id' property of the object will be the key.
              keyPath: 'id',
              // If it isn't explicitly set, create a value by auto incrementing.
              autoIncrement: false,
            });
            // Create an index on the 'date' property of the objects.
            store1.createIndex('notebookID', 'id');
            // store1.createIndex("hashServer", "hashServer");


            // -------------------------------------------------
            // Notebook List Table
            // -------------------------------------------------
            const store2 = db.createObjectStore(this.OBJECT_STORE_NAME_NOTEBOOKLISTINFO, {
              // The 'id' property of the object will be the key.
              keyPath: 'id',
              // If it isn't explicitly set, create a value by auto incrementing.
              autoIncrement: false,
            });
            // Create an index on the 'date' property of the objects.
            store2.createIndex('notebookID', 'id');               // unique

            store2.createIndex("isMyNotebooks", "isMyNotebooks", {
              unique: false,
              multiEntry: true,
            });

            // -------------------------------------------------
            // Notes Table
            // -------------------------------------------------
            const store3 = db.createObjectStore(this.OBJECT_STORE_NAME_NOTES, {
              // The 'id' property of the object will be the key.
              keyPath: 'id',
              // If it isn't explicitly set, create a value by auto incrementing.
              autoIncrement: false,
            });
            // Create an index
            store3.createIndex('notebookID', 'notebookID', {
              unique: false,
              multiEntry: false,
            });
            store3.createIndex('noteID', 'id');           // unique
            // store3.createIndex("hashServer", "hashServer");   // unique
          }

          // -------------------------------------------------
          // .... additional tables below....
          // -------------------------------------------------
          if(oldVersion < 2) {
            const offlineNoteBooksStore = db.createObjectStore(this.OBJECT_STORE_NAME_OFFLINE_NOTEBOOKS, {
              // The 'id' property of the object will be the key.
              keyPath: 'id',
              // If it isn't explicitly set, create a value by auto incrementing.
              autoIncrement: false,
            });
            offlineNoteBooksStore.createIndex('notebookId', 'id');

            const offlineNotesStore = db.createObjectStore(this.OBJECT_STORE_NAME_OFFLINE_NOTES, {
              // The 'id' property of the object will be the key.
              keyPath: 'id',
              // If it isn't explicitly set, create a value by auto incrementing.
              autoIncrement: false,
            });
            offlineNotesStore.createIndex('notebookId', 'notebookId', {
              unique: false,
              multiEntry: false,
            });
            offlineNotesStore.createIndex('noteId', 'id');
          }

        },
        blocked(currentVersion, blockedVersion, event) {
          console.log("INDEX-DB --> BLOCKED", currentVersion, blockedVersion, event);
        },
        blocking(currentVersion, blockedVersion, event) {
          console.log("INDEX-DB --> BLOCKING", currentVersion, blockedVersion, event);
          // -------------------------------------------------------------------------------------------------
          // NOTE: This always seems to get called, even if NOT blocked. Need to look into this further.
          // -------------------------------------------------------------------------------------------------
          // console.log("INDEX-DB --> event.dataLoss", event.type);

          // console.log("Another connection is blocking the version change, current version:", currentVersion, "blocked version:", blockedVersion);
          // _this.toastr.warning("Forensic Notes is open in another tab or window.<br>Please close the other windows and re-load the app by pressing F5.", 
          // "WARNING - APP NOT UPDATED", {enableHtml: true});
        },
        terminated() {
          console.log("INDEX-DB --> TERMINATED");
        },
      });
    }
    catch (error) {

      console.error("INDEX-DB ---> OPEN DB ERROR", error);
    }

    console.log("INDEX-DB --> SetupDB() completed");

    return true;
  }


  // =============================================================
  // MUST be called before calling other methods that interact
  // with the IndexDB.
  // =============================================================
  async InitializeDBs(): Promise<boolean> {
    console.log("INDEX-DB --> InitializeDB(...) entered");

    await this.OpenDB();

    console.log("INDEX-DB --> InitializeDB(...) leaving");
    return true;
  }

  // =============================================================
  //
  // =============================================================
  private async OpenDB() {
    console.log("INDEX-DB --> OpenDB(...) entered");
    this.indexDB = await openDB(this.DATABASE_NAME);
    console.log("INDEX-DB --> OpenDB(...) leaving");
  }


  // =============================================================
  //
  // =============================================================
  async DeleteAllDatabases() {

    // ------------------------------------------------------------------
    // Instead of Delete the whole DB
    // Just delete the data inside.
    // 
    // NOTE: We had issues trying to delete the DB in Firefox
    // This solves the issue
    // ------------------------------------------------------------------

    await this.DeleteAllNoteRecords().then(() => {
      // success
    })
      .catch((error) => {
        console.error(error);
        this.toastr.error("Failed to Delete Your Encrypted Note Records stored in your browsers IndexDB");
      });


    await this.DeleteAllNoteInformationRecords().then(() => {
      // success
    })
      .catch((error) => {
        console.error(error);
        this.toastr.error("Failed to Delete Your Encrypted Notebook Information Records stored in your browsers IndexDB");
      });

    await this.DeleteAllNotebookListRecords().then(() => {
      // success
    })
      .catch((error) => {
        console.error(error);
        this.toastr.error("Failed to Delete Your Encrypted list of Notebook Records stored in your browsers IndexDB");
      });
  }

  // =============================================================
  //
  // =============================================================
  DeleteAllNoteRecords() {
    return new Promise<void>((resolve, reject) => {
      try {
        const tx = this.indexDB.transaction(this.OBJECT_STORE_NAME_NOTES, 'readwrite');
        const store = tx.objectStore(this.OBJECT_STORE_NAME_NOTES);
        const keyRangeValue = IDBKeyRange.lowerBound("");
        store.delete(keyRangeValue);
        console.log("INDEX-DB --> DeleteAllNoteInformationRecords() Completed");
        resolve();
      } catch (error) {
        console.error("DeleteAllNoteInformationRecords() Failed", error);
        reject(error);
      }
    });


  }

  // =============================================================
  //
  // =============================================================
  DeleteAllNoteInformationRecords() {
    return new Promise<void>((resolve, reject) => {
      try {
        const tx = this.indexDB.transaction(this.OBJECT_STORE_NAME_NOTEBOOKINFO, 'readwrite');
        const store = tx.objectStore(this.OBJECT_STORE_NAME_NOTEBOOKINFO);
        const keyRangeValue = IDBKeyRange.lowerBound("");
        store.delete(keyRangeValue);
        console.log("INDEX-DB --> DeleteAllNoteInformationRecords() Completed");
        resolve();
      } catch (error) {
        console.error("DeleteAllNoteInformationRecords() Failed", error);
        reject(error);
      }
    });
  }


  // =============================================================
  //
  // =============================================================
  DeleteAllNotebookListRecords() {
    return new Promise<void>((resolve, reject) => {
      try {
        const tx = this.indexDB.transaction(this.OBJECT_STORE_NAME_NOTEBOOKLISTINFO, 'readwrite');
        const store = tx.objectStore(this.OBJECT_STORE_NAME_NOTEBOOKLISTINFO);
        const keyRangeValue = IDBKeyRange.lowerBound("");
        store.delete(keyRangeValue);
        console.log("INDEX-DB --> DeleteAllNoteInformationRecords() Completed");
        resolve();
      } catch (error) {
        console.error("DeleteAllNoteInformationRecords() Failed", error);
        reject(error);
      }
    });
  }
}
