import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TforensicTimesZone } from '../../../domain/tforensicTimesZone';
import { TimeZoneService } from '../../../service/time-zone.service';
import { UpdateUserProfileModel } from '../../../domain/UpdateProfile';
// import { UserProfileService } from '../../../service/user-profile.service';
import { ForensicUserProfileService } from '../../../service/forensicUserProfile.service';
import { ToastrService } from 'ngx-toastr';
import {
  DropDownListComponent,
  ChangeEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {
  UploaderComponent,
  RemovingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import { TforensicUserProfile } from 'src/app/domain/tforensicUserProfile';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserModel } from 'src/app/shared/customObjects/userModel';
import { AppComponent } from 'src/app/app.component';
import { OpenAIService } from 'src/app/service/open-ai.service';

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.css'],
})
export class ProfileSettingComponent implements OnInit {

  alltimeZones: TforensicTimesZone[];
  successmsg: string;
  test: any;
  loading = false;


  selectedTimeZone : string = "";

  @ViewChild('ejDialogChangePassword', { static: true })
  ejDialogChangePassword: DialogComponent;
  public targetElement: HTMLElement;
  form: UntypedFormGroup;
  submitted = false;



  constructor(

    private _userProfileService: ForensicUserProfileService,
    private _timeZoneService: TimeZoneService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    public appComponent: AppComponent,
    public openAIService: OpenAIService,
  ) {
    this.form = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zd$@$!%*?&].{7,}'
          ),
        ],
      ],
      c_password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zd$@$!%*?&].{7,}'
          ),
        ],
      ],
    });
    this._timeZoneService.getAllTimezones().subscribe((r) => {
      this.alltimeZones = r.result;
    });

    

  }

  get f() {
    return this.form.controls;
  }
  
  
  
  // =======================================================
  // UPDATE Profile
  // =======================================================
  updateProfile() {

    const legalName = this.appComponent.user.legalName;
  const displayName = this.appComponent.user.displayName;
  const timeZone = this.appComponent.user.timeZone;
  const preferredLanguageISO639 = this.appComponent.user.preferredLanguageISO639;

    if(legalName == ""){
      this.toastr.warning("Legal Name is Required");
      return;
    }

    if(displayName == ""){
      this.toastr.warning("Display Name is Required");
      return;
    }

    if(timeZone == ""){
      this.toastr.warning("Time Zone is Required");
      return;
    }


    this.appComponent.user.timeZone = timeZone;

    console.log("Calling updateUserProfile(...)", this.appComponent.user);

    this.loading = true;
    this._userProfileService.updateUserProfile(this.appComponent.user).subscribe((r) => {
      this.loading = false;
      if (r.success == true) {
        this.toastr.success("Profile Updated");
      } else {
        this.toastr.error("Profile Failed to Update");
      }
    });
  }

  // ---------------------------------------
  // Profile Picture Selected
  // Upload to Server
  // ---------------------------------------
  public proxy: any;
  public newImage: any;
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      let proxy = this;

      reader.onload = (event) => {
        this.newImage = event.target.result;
        

          proxy.compressAndResizeImageIfNeeded(proxy.newImage, 250, 250)
          .then(compressed => {
            proxy.newImage = compressed;
            proxy.appComponent.user.profileImage = proxy.newImage;

           //console.log("Proxy Image = " + proxy.newImage);

            proxy._userProfileService
              .updateUserProfileImage(proxy.newImage)
              .subscribe((r) => {
                if (r.success == true) {
                 console.log('updateUserProfileImage', r);
                  proxy.toastr.success('Profile Image Updated');
                  proxy.ProfileImageSet();

                  this.appComponent.user.profileImage = r.result;
                  // this.profileImage = r.result;

                } else {
                 //console.log('FAILED');
                  proxy.toastr.error(
                    'Failed to Update Profile Image - Please try again'
                  );
                }
              });
          })
      
      };
    }
  }


  // ===============================================
  // RESIZE Image to Max Values Set If Required.
  // ===============================================
  private compressAndResizeImageIfNeeded(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;

      if(img.width < newX || img.height < newY){
        // -------------------------------------------------
        // DO NOT Resize, Just return image as-is
        // Original Smaller than MAX Size
        // -------------------------------------------------
        this.appComponent.user.displayName

       //console.log("Return ORIGINAL");
        res(src);
      }
      else{
        img.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = newX;
          elem.height = newY;
          const ctx = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, newX, newY);
          const data = ctx.canvas.toDataURL();
       //console.log("Return NEW");

          res(data);
        }
      }
      img.onerror = error => rej(error);
    })
  }

  private ProfileImageSet() {
    document.getElementById('lblUploadText').innerText = 'Upload new photo';
    // document.getElementById('divRemovePhoto').style.display = 'inline-block';
  }

  public RemovePhoto() {
    this._userProfileService.updateUserProfileImage('').subscribe((r) => {
      if (r.success == true) {
       //console.log('Removed Photo');

        this.toastr.info('Profile Image Removed');
        this.appComponent.user.profileImage = "";

        document.getElementById('lblUploadText').innerText =
          'Upload your photo';
        document.getElementById('divRemovePhoto').style.display = 'none';
      } else {
       //console.log('FAILED');
        this.toastr.error('Failed to Remove Profile Image - Please try again');
      }
    });
  }

  // ============================
  // Focus Methods
  // ============================
  public focusIn(target: HTMLElement): void {
    target.parentElement.classList.add('e-input-focus');
  }

  //FocusOut Event function for input component
  public focusOut(target: HTMLElement): void {
    target.parentElement.classList.remove('e-input-focus');
  }

  //Focus Event function for input component
  public focusInLeft(target: HTMLElement): void {
    target.parentElement.parentElement.classList.add('e-input-focus');
  }

  //FocusOut Event function for input component
  public focusOutLeft(target: HTMLElement): void {
    target.parentElement.parentElement.classList.remove('e-input-focus');
  }

  //MouseDown Event function for input component
  public onMouseDown(target: HTMLElement): void {
    target.classList.add('e-input-btn-ripple');
  }

  //MouseUp Event function for input component
  public onMouseUp(target: HTMLElement): void {
    let ele: HTMLElement = target;
    setTimeout(() => {
      ele.classList.remove('e-input-btn-ripple');
    }, 500);
  }

  ngOnInit() {
    document.body.classList.add('profile');
    document.body.classList.remove('preference');
    document.body.classList.remove('branding');
    document.body.classList.remove('template');
    document.body.classList.remove('team');
    document.body.classList.remove('forensicWitness');
    document.body.classList.remove('teamuser');
    document.body.classList.remove('notifications');
  }

  changePassword() {
    if (this.appComponent.user.isSocialAccount) {
      this.toastr.info('Passwords');

      var msgToUser =
        'Your are logged in using a social account.<br>' +
        'eg: Gmail, Facebook, LinkedIn<br>' +
        '<p>Changing your password is only possible in your social account.</p>';

      this.toastr.info(msgToUser, '', { enableHtml: true });
      return;
    }
    // else
    this.ejDialogChangePassword.show();
  }

  public hideDialog: EmitType<object> = () => {
    this.form.reset();
    this.ejDialogChangePassword.hide();
  };

  onSubmit(form) {
    if (form.value.password != form.value.c_password) {
      this.toastr.warning('Pasword Must Match');
      return;
    }

    if (form.status == 'VALID') {
       this.loading = true;
       this.submitted = true;
      this._userProfileService
        .updatePassword(form.value.password)
        .subscribe((res: any) => {
          if (res.success) {
            this.toastr.success('Password Updated');
            this.loading = false;
            this.ejDialogChangePassword.hide();
            this.submitted = false;
          }
        });
    } else {
      return;
    }
  }
}
