import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
// import { AppComponent } from "src/app/app.component";
// import { ForensicProjectService } from "src/app/service/forensic-project.service";
import { ForensicProjectServiceV2 } from "src/app/service/project-v2.service";
import { ProjectLevelNaming } from "./ProjectLevelNaming";

@Injectable({
    providedIn: 'root'
})
export class ProjectLevelOptions {

    // File Entry  -- On Add Section Dialog Box 
    // to show the Year DropDown and File Number instead of freeform box.
    showFileEntryNumberAtLevel: number = 1;

    // Digital Forensics File Request
    showAddDigitalForensicsFileRequestAtlevel: number = 2;
    onlyShowAddDigitalForensicsFileRequestIf: boolean = true;
    onlyShowAddDigitalForensicsFileRequestIfValue: string = "DFU";

    // Digital Forensics Exhibit
    showAddDigitalForensicsExhibitAtLevel: number = 1;
    onlyShowAddDigitalForensicsExhibitIf: boolean = true;
    onlyShowAddDigitalForensicsExhibitIfValue: string = "";

    // Video Forensics
    showAddVideoForensicsFileRequest: boolean = true;
    showAddVideoForensicsExhibitAtLevel: number = 1;
    onlyShowAddVideoForensicsExhibitIf: boolean = true;
    onlyShowAddVideoForensicsExhibitIfValue: string = "";

    // Cyber
    showAddCyberFileRequest: boolean = true;
    showAddCyberExhibitAtLevel: number = 1;
    onlyShowAddCyberExhibitIf: boolean = true;
    onlyShowAddCyberExhibitIfValue: string = "";

    // OSINT
    showAddOSINTFileRequest: boolean = true;
    // no exhibits?
    // onlyShowAddVideoForensicsExhibitIf : boolean = true;
    // onlyShowAddVideoForensicsExhibitIfValue : string = "";

    // Private Investigator
    showAddPrivateInvestigatorFileRequest: boolean = true;

    // --------------------------------------------------------------------------
    // IMPORTANT NOTE:
    // AppComponent added to constructor causes an injection error.
    // This information is safe to cache, so cache as it does not change often
    // --------------------------------------------------------------------------

    constructor(
        private forensicProjectService: ForensicProjectServiceV2,
        private toastr: ToastrService,
        // private appComponent: AppComponent,
    ) {

        console.log("default constructor for ProjectLevelOptions()");

        // this.setupProjectLevelOptions();


    }

    // Load(userID){

    // }



    Load(userID: string) {

        // let userID: string = "22";
        // userID = this.appComponent.user.userID;

        this.forensicProjectService
            .getProjectAdminSettings(true, userID)
            .subscribe((result) => {

                console.log("getProjectAdminSettings", result);
                let projectAdminSettings: string[] = result.result;

                this.showFileEntryNumberAtLevel = projectAdminSettings["showFileEntryNumberAtLevel"];

                // Digital Forensics File Request
                this.showAddDigitalForensicsFileRequestAtlevel = projectAdminSettings["showAddDigitalForensicsFileRequestAtlevel"];
                this.onlyShowAddDigitalForensicsFileRequestIf = projectAdminSettings["onlyShowAddDigitalForensicsFileRequestIf"];
                this.onlyShowAddDigitalForensicsFileRequestIfValue = projectAdminSettings["onlyShowAddDigitalForensicsFileRequestIfValue"];

                // Digital Forensics Exhibit
                this.showAddDigitalForensicsExhibitAtLevel = projectAdminSettings["showAddDigitalForensicsExhibitAtLevel"];
                this.onlyShowAddDigitalForensicsExhibitIf = projectAdminSettings["onlyShowAddDigitalForensicsExhibitIf"];
                this.onlyShowAddDigitalForensicsExhibitIfValue = projectAdminSettings["onlyShowAddDigitalForensicsExhibitIfValue"];

                // Video Forensics
                this.showAddVideoForensicsFileRequest = projectAdminSettings["showAddVideoForensicsFileRequest"];
                this.showAddVideoForensicsExhibitAtLevel = projectAdminSettings["showAddVideoForensicsExhibitAtLevel"];
                this.onlyShowAddVideoForensicsExhibitIf = projectAdminSettings["onlyShowAddVideoForensicsExhibitIf"];
                this.onlyShowAddVideoForensicsExhibitIfValue = projectAdminSettings["onlyShowAddVideoForensicsExhibitIfValue"];


                // Cyber
                this.showAddCyberFileRequest = projectAdminSettings["showAddCyberFileRequest"];
                this.showAddCyberExhibitAtLevel = projectAdminSettings["showAddCyberExhibitAtLevel"];
                this.onlyShowAddCyberExhibitIf = projectAdminSettings["onlyShowAddCyberExhibitIf"];
                this.onlyShowAddCyberExhibitIfValue = projectAdminSettings["onlyShowAddCyberExhibitIfValue"];

                // OSINT
                this.showAddOSINTFileRequest = projectAdminSettings["showAddOSINTFileRequest"];

                // Private Investigator
                this.showAddPrivateInvestigatorFileRequest = projectAdminSettings["showAddPrivateInvestigatorFileRequest"];



            },
                (error) => {

                    console.error(error);
                });
    }





    showFileNumberEntry(projectLevel: number): boolean {

        console.log("showFileNumberEntry-projectLevel", projectLevel);

        // ---------------------------------------------------------------------------------
        // showFileEntryNumberAtLevel should be FORLevel
        // since we want to show it at the level PRIOR to the one the user selected.
        // so -1 from this value to get this correct.
        // ---------------------------------------------------------------------------------
        let showFileEntryForLevel = this.showFileEntryNumberAtLevel-1;

        console.log("showFileEntryForLevel", showFileEntryForLevel);

        if (projectLevel == showFileEntryForLevel) {
            return true;
        }

        return false;
    }


    showDigitalForensicsFileRequest(projectLevel: number, currLevelName: string): boolean {

        console.log("showDigitalForensicsFileRequest", projectLevel);

        if (projectLevel == this.showAddDigitalForensicsFileRequestAtlevel) {

            console.log("showDigitalForensicsFileRequest - Level Match --> currLevelName", currLevelName);


            // Correct Project level.. Limited by name?
            if (this.onlyShowAddDigitalForensicsFileRequestIf) {
                // Name must be found in comma delimited list
                if (this.onlyShowAddDigitalForensicsFileRequestIfValue.indexOf(currLevelName) > -1) {
                    // Found
                    return true;
                }
                else {
                    // Not found
                    return false
                }
            }

            // else
            return true;
        }

        return false;
    }





}