export default class stringUtils {


    /*
        Following filename and extension handle filenames with multiple .
        eg; my.best.text.mp4
    */
    static getFileNameNoExtension(fullFileName: string) {
        var fileNameOnly = fullFileName.substr(0, fullFileName.lastIndexOf('.'));

        return fileNameOnly;
    }

    static getExtensionOnly(fullFileName: string): string {
        console.log("GetExtension() for file = " + fullFileName);

        var extension = fullFileName.split('.').reverse()[0].toLowerCase();

        console.log("GetExtension() extension = " + extension);

        return extension;

        // var extensionOnly = fullFileName.substr(fullFileName.lastIndexOf('.') + 1);

        // return extensionOnly;
    }

    static getDomainNameFromEmail(email: string) {
        var domainName = email.slice(email.lastIndexOf('@') + 1);

        return domainName;
    }

    static getByteCountOfString(s) {
        return encodeURI(s).split(/%..|./).length - 1;
    }


    static replaceAll(str, find, replace) {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }

    static validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    // -----------------------------------------------------------------------------------------------
    // BUG - With <br /> tags above <table...> 
    // Adds a bunch of BR tags.
    // See: https://forensicnotes.atlassian.net/wiki/spaces/DEV/pages/2010218515/SafeHtml+BUG
    // -----------------------------------------------------------------------------------------------
    static FixBrBug(noteText: string) : string {
        let noteTextFixed: string = stringUtils.replaceAll(noteText, "<br />", "<p class='brParagraph'></p>");
        return noteTextFixed;
    }


    /*
        Removes newLines from string and replaces with BR.
        This is required to send the string to the API
    */
    static replaceNewLineWithBR(text: string): string {
        if (text) {
            return text.trim().replace(/\n/g, '<br />');
        }
        else {
            return "";
        }
    }


    /*
        Meant to create a string that can be used for temp IDs on controls
        eg: Upload progress bar
    */
    static createIDfromFileName(fileName: string) {
        return fileName.replace(/_/g, " ");
    }


    static splitStringOnCapitals(theString: string): string {
        const result = theString
            .trim()
            .split(/(?=[A-Z])/)
            .map(element => element.trim());

        let newString: string = "";

        for (let i = 0; i < result.length; i++) {
            newString = newString + " " + result[i];
        }

        return newString;

    }


}