import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
} from '@angular/core';


import { NotificationService } from '../../../service/notification.service';
import { NotificationModel } from '../../../domain/Notification';
import {
  BeforeUploadEventArgs,
  SelectedEventArgs,
  UploadingEventArgs,
  ChangedEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import { EmitType } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

import { NotificationCategoriesModel } from '../../../domain/NotificationCategories';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../../app-config.module';


import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { protectedResources } from '../../../auth-config';


@Component({
  selector: 'app-notifications-setting',
  templateUrl: './notifications-setting.component.html',
  styleUrls: ['./notifications-setting.component.css'],
  providers: [
    // ToolbarService, LinkService, ImageService, HtmlEditorService
  ],
})
export class NotificationsSettingComponent implements OnInit {

  urlBase = protectedResources.apiBaseUrl.endpoint;

  @ViewChild('ejDialogNotificationDelete', { static: true })
  ejDialogNotificationDelete: DialogComponent;

  public targetElementNotificationDelete: HTMLElement;
  editorvalue: any;
  allTeamNotificationCategories: any = [];
  notificationCategories: any = [];
  allTeamNotification: any[] = [];
  notificationId: number;
  Title: string;
  notificationText: string = '';
  NotificationMessage: string;
  selectedCategory: NotificationCategoriesModel;

  public data: { [key: string]: Object }[] = [];
  public fields: Object = { text: 'label', value: 'notificationCategoryId' };
  public tools: object = {
    type: 'Expand',
    items: ['Undo', 'Redo', '|', 'Bold', 'Italic'],
  };
  // allPersonalTemplates: any[] = [];


  isInitialPageLoad: boolean = true;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'notificationID',
    'title',
    'notificationMessage',
    'categoryID',
    'dateNotificationCreated',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // public Editor = ClassicEditor;
  public onChangeCategory: EmitType<ChangedEventArgs> = (args: any) => {
    this.selectedCategory = args;
  };
  url = this.urlBase;
  constructor(
    @Inject(APP_CONFIG) private config,
    private httpClient: HttpClient,
    private _notificationService: NotificationService,
    private toastr: ToastrService,

  ) {
    //
    // console.log("constructor notifications");
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  // dropClick() {
  //   this.data = this.notificationCategories;
  // }

  deleteNotification(
    i: number,
    notificationID,
    title,
    notificationMessage,
    categoryID,
    dateNotificationCreated
  ) {
    this.Title = title;
    // this.NotificationMessage = this.stripHtml(notificationMessage);
    this.NotificationMessage = notificationMessage;

    this.notificationId = notificationID;
    this.ejDialogNotificationDelete.show();
  }
  onOverlayClickNotificationDelete() {
    this.ejDialogNotificationDelete.hide();
  }
  NoDelete() {
    this.ejDialogNotificationDelete.hide();
  }
  YesDeleteNotification() {
    this.httpClient
      .delete(this.url + '/api/Notifications/' + this.notificationId)
      .subscribe((r) => {
        this.refreshTable();
        this.ejDialogNotificationDelete.hide();
      });
  }
  public initilaizeTarget: EmitType<object> = () => {

  };
  public imageSettings: object = {
    saveFormat: 'Base64',
  };


  // Refresh Table
  refreshTable() {
    this._notificationService.GetAllTeamNotification().subscribe((r) => {
      // this.nData = r.result;
      this.allTeamNotification = r.result;
      this.dataSource = new MatTableDataSource(this.allTeamNotification);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  // =======================================================================
  // NgOnInit
  // =======================================================================
  ngOnInit() {

    // console.log("ngOnInit notifications")

    this.initilaizeTarget();
    this._notificationService
      .GetAllTeamNotificationCategories()
      .subscribe((r) => {
        this.allTeamNotificationCategories = r.result;

        console.log("allTeamNotificationCategories", this.allTeamNotificationCategories);

        for (var i = 0; i < this.allTeamNotificationCategories.length; i++) {
          var nCategory = new NotificationCategoriesModel();
          nCategory.notificationCategoryId = this.allTeamNotificationCategories[i]['notificationCategoryId'];
          nCategory.label = this.allTeamNotificationCategories[i]['label'];
          nCategory.audience = this.allTeamNotificationCategories[i]['audience'];
          this.notificationCategories.push(nCategory);
        }
      });

    console.log("allTeamNotificationCategories", this.allTeamNotificationCategories);


    this._notificationService.GetAllTeamNotification().subscribe((r) => {
      // this.nData = r.result;
      this.allTeamNotification = r.result;
      this.dataSource = new MatTableDataSource(this.allTeamNotification);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.isInitialPageLoad = false;
    });

    for (var i; i <= this.allTeamNotification.length; i++) {
      this.allTeamNotification[i]['notificationMessage'] = this.stripHtml(
        this.allTeamNotification[i]['notificationMessage']
      );
    }

  }


  public stripHtml(html) {
    var temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  clickNotificationType(notificationCategoryID: number) {
    console.log("clickNotificationType", notificationCategoryID);

  }

  sendNotification(notificationID: string) {


    // console.log("Message", this.notificationText);
    // console.log("notificationID", notificationID);

    // ------------------------------------------------------------------------
    // For now - Hard-code Group Message
    // Other options hidden until we have a reason to add BOLF and others.
    // ------------------------------------------------------------------------
    // notificationID = "10";

    console.log("sendNotification", notificationID);



    if (this.notificationText != '' && this.notificationText != null && notificationID != null) {
      this._notificationService
        .CreateTeamNotification(
          notificationID,
          this.notificationText
        )
        .subscribe((r) => {

          // -----------------------------
          // Notification Created.
          // -----------------------------

          this.notificationText = '';
          this.data = [];

          this.toastr.success("Notification Sent to Team Members");

          this.refreshTable();

        });
    } else {

      // ------------------------------------
      // Notification Message Entered?
      // ------------------------------------
      if (!this.notificationText) {
        this.toastr.warning("You must enter a Notification Message");
      }

      // --------------------------------
      // Notification Type Select?
      // --------------------------------
      if (!notificationID) {
        this.toastr.warning("You must select a Notification Type");

      }
    }
  }
  // public onChange({ editor }: ChangeEvent) {
  //   this.editorvalue = editor.getData();
  // }
}
