<div class="preference-content">
  <h1>
    Preferences
  </h1>
  <div class="app-types">
    <button ejs-button class="app-types-simple" id="simpleApp" (click)="simpleApp()">Simple App</button>
    <!-- <input type="button" class="app-types-simple" id="simpleApp" (click)="simpleApp()" value="Simple App" /> -->
    <button ejs-button class="app-types-advance" id="advancedApp" (click)="advancedApp()">Advanced App</button>
    <!-- <input type="button" class="app-types-advance" id="advancedApp" (click)="advancedApp()" value="Advanced App" /> -->
  </div>
  <div class="simple-content" id="simple-content">
    <div>
      <h2 class="p-head">
        <mat-checkbox (change)="timestamp($event)" [checked]="alwaysStayOnCurrentNoteOnTimestamp"
          class="example-margin">Create New Note after clicking the 'Timestamp' button (recommended)</mat-checkbox>
      </h2>
      <p>A new note will be created when you timestamp a previously un-timestamped note.</p>
      <p>The new note will immediately open in edit mode while the existing note is timestamped in the background.
      </p>
    </div>
    <div>
      <h1>Editor Toolbar Mode</h1>
      <mat-radio-group>
        <mat-radio-button id="toolbarMode_{{toolbarMode.value}}" [checked]="toolbarMode.checked" name="ToolbarMode"
          (click)="etMode(toolbarMode.value)" class="example-radio-button" *ngFor="let toolbarMode of toolbarModes"
          [value]="toolbarMode.value">
          {{toolbarMode.name}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <h1>Timestamp Shortcut Key</h1>
      <mat-radio-group>
        <mat-radio-button id="shortcutKey_{{shortcutKey.value}}" [checked]="shortcutKey.checked" name="ShortcutKey"
          (click)="tsshort(shortcutKey.value)" class="example-radio-button" *ngFor="let shortcutKey of shortcutKeys"
          [value]="shortcutKey.value">
          {{shortcutKey.name}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div id="successfullp" style="display: block; color: #055cc3; font-weight: bold;">
      {{successmsgp}}
    </div>
    <div>
      <button ejs-button class="pref" (click)="preferenceUpdate()">Submit</button>
    </div>
  </div>
  <div class="advance-content" id="advance-content">
    <p>Show :</p>
    <ejs-dropdownlist id='ddlelement' #samples [dataSource]='data' [fields]='fields' [placeholder]='text'>
    </ejs-dropdownlist>
    <p>Notebooks on main page.</p>
  </div>
</div>
