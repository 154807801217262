import {
  Component,
  ViewChild,
  Inject,
  OnInit,
  ElementRef,
} from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { TemplateModel } from '../../../domain/Template';
import { TemplateService } from '../../../service/template.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { UserProfileService } from '../../../service/user-profile.service';
import { ForensicUserProfileService } from '../../../service/forensicUserProfile.service';

import { TeamPreviewModel } from '../../../domain/TeamPreview';
import { TemplateTextModel } from '../../../domain/TemplateText';
import { PersonalPreviewModel } from '../../../domain/PersonalPreview';
import { EmitType } from '@syncfusion/ej2-base';
// import { timeout } from 'q';
import { NgModule, InjectionToken } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../app-config.module';
import { AppComponent } from 'src/app/app.component';


@Component({
  selector: 'app-template-setting',
  templateUrl: './template-setting.component.html',
  styleUrls: ['./template-setting.component.css'],
})
export class TemplateSettingComponent implements OnInit {
  @ViewChild('ejDialogMoveToTeam', { static: true })
  ejDialogMoveToTeam: DialogComponent;
  @ViewChild('containerMoveToTeam', { static: true, read: ElementRef })
  containerMoveToTeam: ElementRef;
  public targetElementMoveToTeam: HTMLElement;

  @ViewChild('ejDialogTempPreview', { static: true })
  ejDialogTempPreview: DialogComponent;
  @ViewChild('containerTempPreview', { static: true, read: ElementRef })
  containerTempPreview: ElementRef;
  public targetElementTempPreview: HTMLElement;

  @ViewChild('ejDialogTempDelete', { static: true })
  ejDialogTempDelete: DialogComponent;
  @ViewChild('containerTempDelete', { static: true, read: ElementRef })
  containerTempDelete: ElementRef;
  public targetElementTempDelete: HTMLElement;

  allPersonalTemplates: TemplateModel[];
  Ptemp: any[] = [];
  allTeamTemplates: TemplateModel[];
  isGroupAdmin: boolean = false;
  content: string;
  teamPreviews: TeamPreviewModel[];
  personalPreviews: PersonalPreviewModel[];
  templateIdToMove: string;
  templateIdToPreview: string;
  templateIdToDelete: string;
  TemplateName: string;
  tempText: any;
  FAQ: string = this.config.FAQ;

  constructor(
    public _userProfileService: ForensicUserProfileService,
    public dialog: MatDialog,
    private _templateService: TemplateService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private appComponent: AppComponent
  ) {
    this._templateService.GetAllPersonalTemplates().subscribe((r) => {
      this.allPersonalTemplates = r.result;
      for (var i = 0; i < this.allPersonalTemplates.length; i++) {
        ////console.log(this.allPersonalTemplates[i]);
        // var temp = new TemplateModel();
        // temp.templateId = this.allPersonalTemplates[i].templateId;
        // temp.nodeType = this.allPersonalTemplates[i].nodeType;
        // temp.subType = this.allPersonalTemplates[i].subType;
        // temp.title = this.allPersonalTemplates[i].title;
        // this._templateService.PreviewTemplate(this.allPersonalTemplates[i].templateId).subscribe(r => {
        //   var pTemp = new TemplateTextModel();
        //   pTemp.templateId = temp.templateId;
        //   pTemp.nodeType = temp.nodeType;
        //   pTemp.subType = temp.subType;
        //   pTemp.title = temp.title;
        //   pTemp.tempContent = r.result;
        //  //console.log(pTemp);
        // });
      }
    });
    ////console.log(this.Ptemp);
    this._templateService.GetAllTeamTemplates().subscribe((r) => {
      this.allTeamTemplates = r.result;
      for (var i = 0; i < this.allTeamTemplates.length; i++) {}
    });
  }

  singleuser() {
    document.body.classList.add('singleuser');
    document.body.classList.remove('teamuser');
  }
  teamuser() {
    document.body.classList.add('teamuser');
    document.body.classList.remove('singleuser');
  }
  closePrePopup() {
    this.ejDialogTempPreview.hide();
    setTimeout(() => {
      this.content = '';
    }, 500);
  }
  editTempPopup(a, templateId: string) {
    document
      .getElementById('rename-temp_' + templateId + '')
      .classList.add('active');
    // document.getElementById('edit-temp_'+templateId+'').classList.toggle('show-edit');

    if (
      document
        .getElementById('rename-temp_' + templateId + '')
        .classList.contains('active')
    ) {
      var i;
      for (i = 0; i < document.getElementsByClassName('edit-div').length; i++) {
        var openDropdown = document.getElementsByClassName('edit-div')[i];
        if (openDropdown.classList.contains('show-edit')) {
          openDropdown.classList.remove('show-edit');
          openDropdown.classList.remove('active');
        }
      }
    }
    if (
      document
        .getElementById('rename-temp_' + templateId + '')
        .classList.contains('active')
    ) {
      document
        .getElementById('rename-temp_' + templateId + '')
        .classList.add('show-edit');
      document
        .getElementById('rename-temp_' + templateId + '')
        .classList.add('active');
    }
  }

  closeTempEdit(templateId: string) {
    var i;
    for (i = 0; i < document.getElementsByClassName('edit-div').length; i++) {
      var close = document.getElementsByClassName('edit-div')[i];
      if (close.classList.contains('show-edit')) {
        close.classList.remove('show-edit');
      }
    }

    // document.getElementById('edit-temp_'+templateId+'').classList.remove('show-edit');
  }

  public initilaizeTarget: EmitType<object> = () => {
    this.targetElementMoveToTeam = this.containerMoveToTeam.nativeElement.parentElement;
    this.targetElementTempPreview = this.containerTempPreview.nativeElement.parentElement;
    this.targetElementTempDelete = this.containerTempDelete.nativeElement.parentElement;
    // this.targetElement1 = this.containerOrg.nativeElement.parentElement;
  };

  renameTemplate(templateId: string, tempName: string) {
    var i;
    for (i = 0; i < document.getElementsByClassName('edit-div').length; i++) {
      var close = document.getElementsByClassName('edit-div')[i];
      if (close.classList.contains('show-edit')) {
        close.classList.remove('show-edit');
      }
    }

    if (tempName.length > 0) {
      this._templateService
        .RenameTemplate(templateId, tempName)
        .subscribe((r) => {
          if (r.statusCode == '200') {
            document
              .getElementById('edit-temp_' + templateId + '')
              .classList.remove('show-edit');
            this.refresh();
            // document.body.classList.remove('profile');
          }
        });
    }

    var close = document.getElementsByClassName('edit-div')[0];
    if (close.classList.contains('show-edit')) {
      close.classList.remove('show-edit');
    }
  }
  moveToTeam = function (event: any, templateId: string): void {
    this.templateIdToMove = templateId;
   //console.log(this.templateIdToMove);
    // Call the show method to open the Dialog
    this.ejDialogMoveToTeam.show();
  };
  public onOverlayClickMoveToTeam: EmitType<object> = () => {
    this.ejDialogMoveToTeam.hide();
  };
  CloseMoveToTeam() {
    this.ejDialogMoveToTeam.hide();
  }
  YesMoveToTeam() {
    this._templateService.MoveToTeam(this.templateIdToMove).subscribe((r) => {
     //console.log(r);
      if (r.statusCode == '200') {
        this.refresh();
        this.ejDialogMoveToTeam.hide();
      }
    });
  }

  // moveToTeam(templateId: string, nodeType: number, subType: number, title: string){
  //   const dialogConfig4 = new MatDialogConfig();
  //   dialogConfig4.data = {
  //     tempId : templateId
  //   };
  //   let dialogRef4 = this.dialog.open( PersonalTemplateComponent , dialogConfig4 ).afterClosed().subscribe(item => {
  //     this.refresh();
  //   });
  // }

  previewTempPopup(event: any, templateId: string, title: string) {
    if (title.length > 20) {
      title = title.substring(0, 20) + '...';
    }
    this.TemplateName = title;
    this._templateService.PreviewTemplate(templateId).subscribe((r) => {
      this.content = r.result;
    });
    this.ejDialogTempPreview.show();
  }
  onOverlayClickTempPreview() {
    this.ejDialogTempPreview.hide();
    setTimeout(() => {
      this.content = '';
    }, 500);
  }

  deleteTempPopup(event: any, templateId: string) {
    this.templateIdToDelete = templateId;
    this.ejDialogTempDelete.show();
  }
  onOverlayClickTempDelete() {
    this.ejDialogTempDelete.hide();
  }
  NoDelete() {
    this.ejDialogTempDelete.hide();
  }
  YesDelete() {
    this._templateService
      .DeleteTemplate(this.templateIdToDelete)
      .subscribe((r) => {
        this.refresh();
        this.ejDialogTempDelete.hide();
      });
  }

  refresh() {
    this._templateService.GetAllPersonalTemplates().subscribe((r) => {
      this.allPersonalTemplates = r.result;
    });

    this._templateService.GetAllTeamTemplates().subscribe((r) => {
      this.allTeamTemplates = r.result;
    });
  }

  ngOnInit() {
    this.initilaizeTarget();
    document.body.classList.add('template');
    document.body.classList.add('singleuser');
    document.body.classList.remove('preference');
    document.body.classList.remove('profile');
    document.body.classList.remove('branding');
    document.body.classList.remove('team');
    document.body.classList.remove('forensicWitness');
    document.body.classList.remove('teamuser');
    document.body.classList.remove('notifications');

    console.log("getUserProfile()");

    this._userProfileService.getUserProfile(this.appComponent.securityUtils.isServiceWorkerCacheEnabled(), this.appComponent.user.userID).subscribe((r) => {
      if (r.success == true) {
        this.isGroupAdmin = r.result.isGroupAdmin;
      }
    });
  }
}
