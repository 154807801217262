  <div [ngClass]="{'hidden' : !isComponentVisible}">
  <!-- Loading GIF Panel -->
  <div #loadingDiv class="loadingDiv" *ngIf="isInitialPageLoad">
    <img src="/assets/images/Forensic_Notes_Logo.png" 
      class="loadingLogo"tooltip="Loading Forensic Notes" />
    <img src="/assets/images/loading-gif-png-5.gif" 
      class="loadingAppGif"
    tooltip="Loading Forensic Notes" />
  </div>

<div class="center"  [ngClass]="{'hidden' : isInitialPageLoad}">
  <h1>Branding</h1>
  <div class="instruction">
    <b>Instructions:</b>
    <br>Click on the
    <br>Banner/Logo (top)
    <br>or
    <br>Disclaimer (bottom)
    <br>to customize reports for your organization.
  </div>
  <p class="smallgrey">Note: A rough example of the final cover page. </p>
  <p class="smallgrey">Print actual notebook PDF to see the final product. </p>
  <div class="pdf-main">
    <div  title="Click to Customize Banner/Header Area">
      <div *ngIf="showbanner" class="hand banner" (click)="openOrgDialog($event)">
        <!-- <div class="instruct" (click)="openOrgDialog($event)"> -->
        <img src="{{ updateBrand.bannerPath }}" class="PDFBannerImage" />
        <!-- </div> -->
      </div>
      <div *ngIf="showLogo" class="hand" (click)="openOrgDialog($event)">
        <img src="{{ updateBrand.logoPath }}" class="PDFLogoImage" />
        <div class="orgName">{{ updateBrand.organizationName }}</div>
      </div>
     
    </div>
    <div>
      <div (click)="ejDialogNotebookName.show()" class="notebook-name help" title="Click to view more information">
        <div>Name Of Notebook</div>
      </div>
      <div (click)="ejDialogOwnerName.show()" class="name-of-current help" title="Click to view more information">
        <div class="cnNotesHeading">Contemporaneous Notes of</div>
        <div class="cnDisplayName">{{ appComponent.user.displayName }}</div>
      </div>
      <div (click)="ejDialogDates.show()" class="date-of-current help" title="Click to view more information">
        <p>
          <b>{{ fromdate }}</b>
          <br />
          to
          <br />
          <b>{{ todate }}</b>
        </p>
      </div>
      <div (click)="ejDialogTimeZone.show()" class="current-timezone hand" title="Click to view more information">All Times Displayed are in: {{ appComponent.user.timeZone }}</div>
      <div>
     <editor #disclaimerTiny [(ngModel)]="updateBrand.notebookDisclaimer"
      [init]="{
     base_url: '/tinymce',
     suffix: '.min',
     paste_data_images: true,
     paste_as_text: true,
     imagetools_cors_hosts: ['picsum.photos'],
     paste_webkit_styles: 'all',
    menubar: '',
     toolbar: [
      'undo redo | bold forecolor | numlist bullist table'
     ],
     plugins: [
       'paste importcss image table charmap hr advlist lists wordcount imagetools textpattern quickbars'
     ],
     textpattern_patterns: [
    {start: '*', end: '*', format: 'italic'},
    {start: '**', end: '**', format: 'bold'},
  ],
    toolbar_sticky: false,
    image_advtab: true,
  height: 600,
  image_caption: true,
  quickbars_selection_toolbar: 'bold italic | h2 h3 blockquote | quickimage | inserttable',
  quickbars_insert_toolbar: 'quickimage quicktable | charmap hr | insertdatetime',
  toolbar_mode: 'floating',
  contextmenu: 'image imagetools table inserttable insertdatetime quickimage',
  content_style: [
    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' + 
    'img {max-width: 100%;}'
    ]
   
   }"></editor>
      </div>
    </div>
    
  </div>
  <!-- (end of) Page outline -->
  <div class="buttonSectionOneCol">
    <button ejs-button class="success" (click)="updateNotice()">Update Disclaimer / Info Section</button>
  </div>
  </div>


  <div #containerOrg class="root-container">
    <ejs-dialog id="dialog" #ejDialogOrganization isModal="true" (overlayClick)="onOverlayClick()" showCloseIcon="true"
      header="Organization Information" width="600px" [visible]="false">



      <mat-form-field appearance="outline">
        <mat-label>Organization Name</mat-label>
        <input #org_name matInput placeholder="Enter organization name..." minlength="1" maxlength="30"
        [(ngModel)]="updateBrand.organizationName" />
      </mat-form-field>
 

      <br>

      <div class="organise">
        <h2>Square Logo:</h2>
        <p>Square logo placed next to Organization Name provided above</p>
        <ejs-uploader maxFileSize="5242880" name="UploadLogo" #defaultupload multiple="false"
          allowedExtensions=".jpg,.png" [autoUpload]="true" [dropArea]="dropEleLogo" [asyncSettings]="pathLogo"
          (uploading)="addHeaders($event)" (success)="onUploadSuccessLogo($event)"
          (failure)="onUploadFailureLogo($event)"
          ></ejs-uploader>
        <!-- <input name="UploadLogo" id="UploadLogo" type="file" data-role="upload" multiple="multiple" autocomplete="off"> -->
      </div>
      <div class="or">OR</div>
      <div class="organise">
        <h2>Banner:</h2>
        <p>Should include both your Logo and Organization Name</p>
        <ejs-uploader maxFileSize="5242880" name="UploadBanner" #defaultupload multiple="false"
          allowedExtensions=".jpg,.png" [autoUpload]="true" [dropArea]="dropEleBanner" [asyncSettings]="pathBanner"
          (uploading)="addHeaders($event)" (success)="onUploadSuccessBanner($event)"
          (failure)="onUploadFailureBanner($event)"></ejs-uploader>
      </div>
      <div class="buttonSectionTwoCol">
        <button ejs-button (click)="closeOrg()" class="cancel">Cancel</button>
        <button ejs-button class="success" (click)="UpdateOrgName()">Update</button>
      </div>
    </ejs-dialog>
  </div>

  <div #containerDisclaimer></div>

  <!-- TIP - Notebook Name Dialog -->
  <ejs-dialog #ejDialogNotebookName showCloseIcon="true" header="Notebook Name" isModal="true"
    (overlayClick)="onOverlayClick()" width="400px" [visible]="false">
    <p>Display's the name of the notebook as set by the notebook owner.</p>
    <p class="notEditable">NOT EDITABLE</p>
  </ejs-dialog>


  <!-- TIP - Owner Name Dialog -->
  <ejs-dialog #ejDialogOwnerName showCloseIcon="true" header="Notebook Owner Name" isModal="true"
    (overlayClick)="onOverlayClick()" width="400px" [visible]="false">
    <p>Display's the name of the Notebook Owner.</p>
    <p class="notEditable">NOT EDITABLE</p>
  </ejs-dialog>


  <!-- TIP - Dates Dialog -->
  <ejs-dialog #ejDialogDates showCloseIcon="true" header="Notebook Dates" isModal="true"
    (overlayClick)="onOverlayClick()" width="400px" [visible]="false">
    <p>Display's the dates of the First and Last notes
      entered into the Notebook you print.</p>
    <p class="notEditable">NOT EDITABLE</p>
  </ejs-dialog>

  <!-- TIP - Timezone Dialog -->
  <ejs-dialog #ejDialogTimeZone showCloseIcon="true" header="Timezone" isModal="true" (overlayClick)="onOverlayClick()"
    width="400px" [visible]="false">
    <p>Display's the Timezone as set by the user in the Profile Settings.</p>
  </ejs-dialog>
</div>