import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { TforensicNoteBook } from '../domain/tforensicNoteBook';
import { TforensicNote } from '../domain/tforensicNote';
import { Tforensic } from '../domain/tforensic';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { protectedResources } from '../auth-config';
import { AppComponent } from '../app.component';
import URLUtils from '../shared/Utility/urlUtils';


@Injectable({
  providedIn: 'root'
})
export class ForensicNoteBookService {



  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) { }


  // url = this.config.apiBaseUrl;
  url = protectedResources.apiBaseUrl.endpoint;

  // ===========================================================================
  //
  // ===========================================================================
  getNoteBookByNoteBookId(notebookID: string, minTimestampDate: string, enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<TforensicNote> {
    const httpPostOptions =
    {
      headers:
        new HttpHeaders(
          {
            "Content-Type": "application/json"
          }),
      withCredentials: true,

      params: {
        "minTimestampDate": minTimestampDate
      }
    }

    let fullURL: string = this.url + '/api/Notebooks/' + notebookID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.http.get<TforensicNote>(fullURL, httpPostOptions);
  }


  // ===========================================================================
  // TO BE DEPRECATED
  // ===========================================================================
  getAllforensicnotebooks(
    startIndex: string, 
    numberToDisplay: string,
    searchText: string, 
    ownerType: string,
    enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<{success: boolean, result: TforensicNoteBook[]}> {

    const httpPostOptions =
    {
      headers:
        new HttpHeaders(
          {
            "Content-Type": "application/json"
          }),
      withCredentials: true,

      // observe: 'response' as 'response',
      params: {
        "startIndex": startIndex,
        "numberToDisplay": numberToDisplay,
        "searchText": searchText,
        "ownerType": ownerType
      }
    }

    console.log("url-getAllforensicnotebooks", this.url);
    console.log("httpPostOptions", httpPostOptions);

    let fullURL: string = this.url + '/api/Notebooks/List';
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    console.log("url-getAllforensicnotebooks", fullURL);


    return this.http.get<TforensicNoteBook>(fullURL, httpPostOptions);
  }

  // ===========================================================================
  //
  // ===========================================================================
  getAllForensicNotebooksV2(
    startIndex: number,
    numberToDisplay: number,
    searchText: string,
    ownerType: string,
    showSoftDeleted: boolean,
    enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<any> {

    const httpPostOptions =
    {
      headers:
        new HttpHeaders(
          {
            "Content-Type": "application/json"
          }),
      withCredentials: true,

      // observe: 'response' as 'response',
      params: {
        "startIndex": startIndex.toString(),
        "numberToDisplay": numberToDisplay.toString(),
        "searchText": searchText,
        "showSoftDeleted": showSoftDeleted,
        "ownerType": ownerType
      }
    }

    console.log("url-getAllforensicnotebooks", this.url);
    console.log("httpPostOptions", httpPostOptions);

    let fullURL: string = this.url + '/api/Notebooks/ListV2'; // V2
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    console.log("url-getAllforensicnotebooks", fullURL);


    return this.http.get<any>(fullURL, httpPostOptions);
  }

  // ===========================================================================
  //
  // ===========================================================================
  ListAllNotebooks(
    ownerType: string,
    showSoftDeleted: boolean,
    excludeNotebookIDList: string[],
    userID: string
  ): Observable<any> {


    // ---------------------------------------------------------
    // Place all HASHES into a single - separated string.
    // API on Server will properly separate them.
    // ---------------------------------------------------------
    let dashSeparatedList: string = "";
    if (excludeNotebookIDList) {
      console.log("excludeNoteIDList.length", excludeNotebookIDList.length);
      dashSeparatedList = excludeNotebookIDList.join("-");
    }

    // -----------------------------------------------------------------------------
    // IMPORTANT NOTE:
    // We are using a POST instead of a GET request due to the size of the 
    // HASH list. 
    // DO NOT put back to GET as it will cause periodic requests to fail
    // -----------------------------------------------------------------------------
    const formData = new FormData();
    formData.append("ownerType", ownerType);
    formData.append("showSoftDeleted", showSoftDeleted.toString());
    formData.append("excludedHashesDashSeparatedList", dashSeparatedList);
    
    // Exclude NoteID List --> Can be blank
    console.log("dashSeparatedList", dashSeparatedList);

    let fullURL: string = this.url + '/api/Notebooks/ListAllNotebooks'; // V3
    fullURL = URLUtils.setURLCachingParam(fullURL, false, userID);

    console.log("url-getAllforensicnotebooks", fullURL);


    const httpPostOptions = {
      withCredentials: true,
    };

    console.log("httpPostOptions", httpPostOptions);


    return this.http.post<any>(fullURL, formData, httpPostOptions);
  }


  // // ===========================================================================
  // //
  // // ===========================================================================
  // getLimitedNotesInNotebook(notebookID: string, totalNumberOfNotesInNotebook: number, userID: string): Observable<any> {


  //   console.log("A-INDEX-DB --> getLimitedNotesInNotebook() called...", totalNumberOfNotesInNotebook);

  //   let NOTE_LIMIT: number = 10;

  //   let startIndex = totalNumberOfNotesInNotebook - NOTE_LIMIT;
  //   if(startIndex < 0){
  //     startIndex = 0;
  //   }

  //   console.log("A-INDEX-DB --> getLimitedNotesInNotebook() StartIndex = ", startIndex);


  //   return this.getXNotesInNotebook(notebookID, [], startIndex, NOTE_LIMIT, true, userID);




  // }


  // ===========================================================================
  //
  // ===========================================================================
  getAllNotesInNotebook(notebookID: string, excludeNoteIDList: string[],
    userID: string,
    useLowerQualityImagesIfCacheDisabled: boolean = false
  ): Observable<any> {

    console.log("A-INDEX-DB --> getAllNotesInNotebook() called...");

    return this.getXNotesInNotebook(notebookID, excludeNoteIDList, 0, 1000,     // <------------------ HERE!!!!!!!!!!!!!!
      useLowerQualityImagesIfCacheDisabled, userID);

  }

  // ===========================================================================
  //
  // ===========================================================================
  private getXNotesInNotebook(notebookID: string, excludeNoteIDList: string[],
    startIndex: number, numberOfNotesToReturn: number, useLowerQualityImagesIfCacheDisabled: boolean, userID: string
  ): Observable<any> {


    console.log("A-INDEX-DB --> getXNotesInNotebook() called...", useLowerQualityImagesIfCacheDisabled, numberOfNotesToReturn);


    // -----------------------------------------------------------------------
    // NEVER want to Cache this API call - Use Custom Caching instead
    // -----------------------------------------------------------------------
    let enableServiceWorkerCache = false;

    // ---------------------------------------------------------
    // Place all HASHES into a single - separated string.
    // API on Server will properly separate them.
    // ---------------------------------------------------------
    let dashSeparatedList: string = "";
    if (excludeNoteIDList) {
      console.log("excludeNoteIDList.length", excludeNoteIDList.length);
      dashSeparatedList = excludeNoteIDList.join("-");
    }

    // -----------------------------------------------------------------------------
    // IMPORTANT NOTE:
    // We are using a POST instead of a GET request due to the size of the 
    // HASH list. 
    // DO NOT put back to GET as it will cause periodic requests to fail
    // -----------------------------------------------------------------------------
    const formData = new FormData();

    // Image Quality
    if (useLowerQualityImagesIfCacheDisabled) {
      formData.append("imageOptions", "100, 100");
    }
    else {
      formData.append("imageOptions", "200,200");
    }
    formData.append("excludedHashesDashSeparatedList", dashSeparatedList);

    formData.append("startIndex", startIndex.toString());
    formData.append("numberOfNotesToReturn", numberOfNotesToReturn.toString());


    // Exclude NoteID List --> Can be blank
    console.log("dashSeparatedList", dashSeparatedList);

    // Number of Notes
    console.log("numberOfNotesToReturn", numberOfNotesToReturn);


    const httpPostOptions = {
      withCredentials: true,
    };

    console.log("httpPostOptions", httpPostOptions);

    let fullURL: string = this.url + '/api/Notebooks/' + notebookID + "/GetAllNotesInNotebook";
    // fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    try {
      console.log("GetAllNotesInNotebook API CALLED", fullURL);
      return this.http.post<any>(fullURL, formData, httpPostOptions);
    }
    catch (error) {
      console.log("getAllNotesInNotebook(..)", error);
      throw error;
    }


    // let fullURL: string = this.url + '/api/Notebooks/' + notebookID + "/GetAllNotesInNotebook";
    // fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    // try {
    //   console.log("GetAllNotesInNotebook API CALLED");
    //   return this.http.post<any>(fullURL, formData, httpPostOptions);
    // }
    // catch (error) {
    //   console.log("getAllNotesInNotebook(..)", error);
    //   throw error;
    // }
  }


  // ===========================================================================
  //
  // ===========================================================================
  getNotesInNotebook(notebookID: string, minTimestampDate: string, showTimeStampedOnly: string, startIndex: string,
    numberOfNotes: string, sortDirection: string,
    enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<TforensicNote> {

    const httpParams = new HttpParams()
      .set("minTimestampDate", minTimestampDate)
      .set("showTimeStampedOnly", showTimeStampedOnly)
      .set("startIndex", startIndex)
      .set("numberOfNotes", numberOfNotes)
      .set("sortDirection", sortDirection)
      .set("maxDate", "")
      .set("imageOptions", "200,200")
      .set("isMobileAppRequest", "false");

    const paramString = httpParams.toString();

    const httpPostOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
      params: httpParams
    };

    let fullURL: string = this.url + '/api/Notebooks/' + notebookID + "/GetNotes";
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.http.get<TforensicNote>(fullURL, httpPostOptions);
  }




  // ===========================================================================
  //
  // ===========================================================================
  updateNoteBookNameByID(notebookID: string, newNotebookName: string): Observable<TforensicNoteBook> {
    const formData = new FormData();
    formData.append('newNotebookName', newNotebookName);

    const httpPostOptions =
    {
      withCredentials: true
    }

    return this.http.patch<TforensicNoteBook>(this.url + '/api/Notebooks/' + notebookID + "/Rename", formData, httpPostOptions);
  }

  // ===========================================================================
  //
  // ===========================================================================
  deleteSoftNotebook(notebookID: string): Observable<TforensicNoteBook> {
    const formData = new FormData();

    const httpPostOptions =
    {
      withCredentials: true
    }

    return this.http.patch<TforensicNoteBook>(this.url + '/api/Notebooks/' + notebookID + "/DeleteSoft",
      formData, httpPostOptions);
  }

  // ===========================================================================
  //
  // ===========================================================================
  createforensicnotebook(forensicNoteBook: TforensicNoteBook): Observable<Tforensic> {


    const formData = new FormData();
    formData.append('notebookName', forensicNoteBook.title);
    const httpPostOptions =
    {
      withCredentials: true
    }
    return this.http.post<Tforensic>(this.url + '/api/Notebooks',
      formData, httpPostOptions
    );

  }

  // ===========================================================================
  // Notebook Information 
  // ===========================================================================
  getNotebookInformation(notebookID: string, enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<any> {
    const httpPostOptions =
    {
      withCredentials: true
    }

    // -----------------------------------------------------------------------
    // NEVER want to Cache this API call - Use Custom Caching instead
    // -----------------------------------------------------------------------
    enableServiceWorkerCache = true;

    let fullURL: string = this.url + '/api/Notebooks/List/' + notebookID;
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);

    console.log("getNotebookInformation", fullURL);
    // this.getNotebookInformationUpdated(fullURL);

    return this.http.get<any>(fullURL, httpPostOptions);

  }


  // ===========================================================================
  //
  // ===========================================================================
  getBookmardetailByNoteBookId(notebookID: string, enableServiceWorkerCache: boolean,
    userID: string
  ): Observable<any> {
    const httpPostOptions =
    {
      withCredentials: true
    }

    let fullURL: string = this.url + '/api/Notes/' + notebookID + '/Bookmark';
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.http.get<TforensicNoteBook>(fullURL, httpPostOptions);
  }


  // ===========================================================================
  //
  // ===========================================================================
  getAuditLogs(nodeID: string, enableServiceWorkerCache: boolean, userID: string) {
    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      // observe: 'response' as 'response',
      params: {
        nodeID: nodeID,
      },
    };


    let fullURL: string = this.url + '/api/AuditLog/GetAuditLog';
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.http.get<TforensicNoteBook>(fullURL, httpPostOptions);
  }

  // ===========================================================================
  //
  // ===========================================================================
  getDrawingCanvas(enableServiceWorkerCache: boolean, userID: string) {

    const httpPostOptions = {
      withCredentials: true,
    };

    let fullURL: string = this.url + '/api/DrawingCanvas/GetTemplates';
    fullURL = URLUtils.setURLCachingParam(fullURL, enableServiceWorkerCache, userID);


    return this.http.get<TforensicNoteBook>(fullURL, httpPostOptions);
  }

}
