<ejs-dialog id="ejDialogSettings" #ejDialogSettings showCloseIcon='true'
  header='Settings' (beforeClose)="onDialogClose($event)" width="1000" [visible]="isVisible"
  [animationSettings]='animationSettings' [target]="dialogDragTarget" [allowDragging]='allowDialogDragging'
  [enableResize]='false' isModal="true">
  <div class="setting-page">
    <div class="side-1" id="side-1">
      <div class="link-main">
        <ul>
          <li>
            <a href="javascript:void(0)" class="profile" (click)="onChangeComponent('profile')">
              <span class="material-icons md-24 iconBlue menuIcon">person</span>Profile</a>
          </li>
          <li *ngIf="user.isGroupAdmin">
            <a href="javascript:void(0)" class="branding" (click)="onChangeComponent('branding')">
              <span class="material-icons md-24 iconBlue menuIcon">business</span>Branding</a>
          </li>
          <li *ngIf="user.isGroupAdmin">
            <a href="javascript:void(0)" class="team" (click)="onChangeComponent('team')">
              <span class="material-icons md-24 iconBlue menuIcon">groups</span>Team</a>
          </li>
          <li *ngIf="user.isGroupAdmin">
            <a href="javascript:void(0)" class="notifications" (click)="onChangeComponent('notification')">
              <span class="material-icons md-24 iconBlue menuIcon">notifications</span>Notifications</a>
          </li>
          <li *ngIf="user.isGroupAdmin">
            <a href="javascript:void(0)" class="notifications" (click)="onChangeComponent('casemanagement')">
              <span class="material-icons md-24 iconBlue menuIcon">home_repair_service</span>Case Management</a>
          </li>
          <!-- <li *ngIf="isGroupAdmin">
            <a href="javascript:void(0)" class="notifications" (click)="onChangeComponent('assetmanagement')">
              <span class="material-icons md-24 iconBlue menuIcon">app_shortcut</span>Asset Management</a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="main-content" id="main-content">
      <app-profile-setting *ngIf="this.componentName == 'profile'" #componentProfileSettings></app-profile-setting>
      <app-branding-setting #componentBranding [isComponentVisible]="this.componentName == 'branding'" ></app-branding-setting>
      <app-team-setting *ngIf="this.componentName == 'team'"></app-team-setting>
      <app-notifications-setting *ngIf="this.componentName == 'notification'"></app-notifications-setting>
      <app-case-management *ngIf="this.componentName == 'casemanagement'" #componentCaseManagement></app-case-management>
      <app-asset-management *ngIf="this.componentName == 'assetmanagement'"></app-asset-management>
    </div>
  </div>
</ejs-dialog>