import { Injectable } from '@angular/core';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { msalConfig, protectedResources } from '../auth-config';


// =============================================================================
// IMPORTANT - Must wait at least 1.5 seconds before you attempt to get the
// refreshed token after calling RefreshToken()
// 
// Check for undefined and try again.
// =============================================================================


@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  token: any = null;


  // ==========================================
  //
  // ==========================================
  constructor() {

    // --------------------------------------------------------------------
    // NOTE: This Service is setup and called by the APP_INITIALIZER
    // --------------------------------------------------------------------
    console.log("AuthTokenService -- Constructor");

  }


  // =========================================================
  // Called automatically from Constructor
  // Only re-call if token expired.
  //
  // NEED TO TEST to see if token expired or auto-renews
  // =========================================================

  async RefreshToken() {

    console.log("AuthTokenService -- RefreshToken -- entered");



    console.log("AuthTokenService --> RefreshToken()");

    let publicClientApplication = new PublicClientApplication(msalConfig);
    const account = publicClientApplication.getAllAccounts()[0];

    console.log("AuthTokenService --> publicClientApplication = ", publicClientApplication);
    console.log("AuthTokenService --> Account = ", account);

    // -------------------------------------------------------------------------------------
    // Return Promise 
    // The AUTH Token MUST be acquired before this method returns to the calling page
    // -------------------------------------------------------------------------------------
    return new Promise((resolve, reject) => {

      if (!account) {
        // -------------------------------------------------------------------------------------------------
        // User Just Logged In
        // After a User Logs in, the getAllAccounts() fails to obtain the newly logged in Account.
        // We MUST wait at least 1 second before attempting to get it again.
        // Once user is logged in, any page refreshes do not have this issue.
        // -------------------------------------------------------------------------------------------------
        console.error("AuthTokenService --> Account Not Set... Trying again in 1 second...");

        setTimeout(() => {
          console.log("AuthTokenService --> Account Not Set... Trying to acquire again...");
          const account = publicClientApplication.getAllAccounts()[0];
          console.log("AuthTokenService --> Account 2 = ", account);

          if(account == undefined){
            // ------------------------------------
            // Account not found...
            // User needs to login
            // ------------------------------------
            const accessTokenRequest = {
              scopes: [environment.scopes],
            }
      
            publicClientApplication.acquireTokenRedirect(accessTokenRequest);  
          }

          return resolve(this.AcquireToken(account, publicClientApplication));

        }, 2000); // MINIMUM 1000, otherwise, it still fails.

      }
      else {
        console.log("AuthTokenService --> Account Ready on 1st Instance");

        return resolve(this.AcquireToken(account, publicClientApplication));
      }

    });

  }


  // ================================================================================
  // Acquire Token
  // Make sure to return the Promise
  // ================================================================================
  AcquireToken(account, publicClientApplication) {

    return new Promise((resolve, reject) => {

      const accessTokenRequest = {
        scopes: [environment.scopes],
        account: account
      }

      // var _this = this;

      console.log("AuthTokenService --> Attempting to AcquireTokenSilent");

      publicClientApplication.acquireTokenSilent(accessTokenRequest).then(re => {

        console.log("AuthTokenService --> AcquireTokenSilent SUCCESS");

        let refreshedToken = re.accessToken;

        this.token = refreshedToken;

        console.log("AuthTokenService --> _this.refreshedToken", this.token);

        resolve(true);

      })
      .catch(function (error) 
      {
        if (error instanceof InteractionRequiredAuthError) {
          console.error("AuthTokenService --> RefreshToken - Redirecting user to login...", error);
          publicClientApplication.acquireTokenRedirect(accessTokenRequest);
        }
        else {
          console.error("AuthTokenService --> RefreshToken - Login Error #1", error);
          reject(error);
        }
      });
    });

  }


  // ================================================================================
  //
  // ================================================================================
  isTokenExpired() {
    const expiry = (JSON.parse(atob(this.token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }


  // ================================================================================
  //
  // ================================================================================
  refreshAuthTokenIfExpired(){
    if(this.isTokenExpired()){
      // --------------------------------
      // Token Expired - Refresh
      // --------------------------------
      this.RefreshToken();
      console.log("Auth Token Expired - RefreshToken Called");
    }
  }



  // ================================================================================
  //
  // ================================================================================
  getTryAgainRefreshTokenMsg() {
    return "Refreshing Login Token<br>Please try again in a few seconds.<br>If you continue to see this message, please do a page refresh.";
  }


}