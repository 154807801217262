
export class UserModel {
  "userID": string = "";
  "profileImage": string = "";
  "legalName": string = "";
  "displayName": string = "";
  "usersEmail": string = "";
  "timeZone": string = "";
  "ianaTimeZoneId": string = "";
  "currentTimeInUTC": string = "";
  "currentTimeInLocalTime": string = "";
  "agreeToTerms": boolean = false;
  "passwordToProtectDownloadedFiles": string = "";
  "purchaseStatus": number = 0;
  "notebookPageSize": number = 0;
  "loginMessage": string = "";
  "isGroupAdmin": boolean = false;
  "isGlobalAdmin": boolean = false;
  "isSocialAccount": boolean = false;
  "alwaysStayOnCurrentNoteOnTimestamp": boolean = false;
  "editorToolbarMode": number = 0;
  "timeStampShortCut": number = 0;
  "numberOfUnreadAlerts": number = 0;
  "resetPassword": boolean = false;
  "showLegacyApp": true;
  "clientEncryptionKEK": string = "";
  "aiEnabled": boolean = true;
  "aiShown": boolean = true;
  "preferredLanguageISO639": string = "en"  // en = English is default -- Must be ISO 639-1 --> https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
  offlineEncryptionLevel = 2; // 2: enabled Offline Encrytion, 1: Default

  // --------------------------------------------------------
  // Load from API Result
  // --------------------------------------------------------
  LoadFromApiResult(result: any) {

    console.log("LoadFromApiResult", result);

    this.userID = result.userID;
    this.profileImage = result.profileImage;
    this.legalName = result.legalName;
    this.displayName = result.displayName;
    this.usersEmail = result.usersEmail;
    this.timeZone = result.timeZone;
    this.ianaTimeZoneId = result.ianaTimeZoneId;
    this.currentTimeInUTC = result.currentTimeInUTC;
    this.currentTimeInLocalTime = result.currentTimeInLocalTime;
    this.agreeToTerms = result.agreeToTerms;
    this.passwordToProtectDownloadedFiles = result.passwordToProtectDownloadedFiles;
    this.purchaseStatus = result.purchaseStatus;
    this.notebookPageSize = result.notebookPageSize;
    this.loginMessage = result.loginMessage;
    this.isGroupAdmin = result.isGroupAdmin;
    this.isSocialAccount = result.isSocialAccount;
    this.alwaysStayOnCurrentNoteOnTimestamp = result.alwaysStayOnCurrentNoteOnTimestamp;
    this.editorToolbarMode = result.editorToolbarMode;
    this.timeStampShortCut = result.timeStampShortCut;
    this.numberOfUnreadAlerts = result.numberOfUnreadAlerts;
    this.resetPassword = result.resetPassword;
    this.showLegacyApp = result.showLegacyApp;
    this.clientEncryptionKEK = result.clientEncryptionKEK;

    // -------------------------------------------------------------------------------------
    // AI Features
    // Account Level on/off - Set by Admins
    // If false, but aiShown == true, then features will still be shown, but disabled
    // To fully hide the features, ADMIN must set aiShown = false
    // If aiShown == false, then it overrides this setting.
    // -------------------------------------------------------------------------------------

    this.aiEnabled = result.aiEnabled;
    this.aiShown = result.aiShown;

    this.preferredLanguageISO639 = result.preferredLanguageISO639;


  }


  

}

