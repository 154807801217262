import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TforensicTimesZone } from 'src/app/domain/tforensicTimesZone';
import { ForensicUserProfileService } from 'src/app/service/forensicUserProfile.service';
import { TimeZoneService } from 'src/app/service/time-zone.service';
import { BaseDialogComponent } from 'src/app/shared/base/dialog/dialog.component';


@Component({
  selector: 'app-setup-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})

// ===========================================================
// Extends Base Dialog Component in Shared/Base folder.
// ===========================================================
export class TimezoneComponent extends BaseDialogComponent implements OnInit {

  @Output() timeZoneSetEvent = new EventEmitter<string>();

  updateParentTimeZoneSet(timeZone: string) {
    console.log("UpdateParent with ", timeZone);
    this.timeZoneSetEvent.emit(timeZone);
  }

  isTimeZoneSet: boolean = false;

  alltimeZones: TforensicTimesZone[];


  constructor(
    private forensicUserProfileService: ForensicUserProfileService,
    private toastr: ToastrService,
    private _timeZoneService: TimeZoneService,
    public readonly cdRef: ChangeDetectorRef

  ) 
  {
    super(cdRef);
  }

  ngOnInit(): void {

    // Load TimeZones
    this._timeZoneService.getAllTimezones().subscribe((r) => {
      this.alltimeZones = r.result;

    this.isLoading = false;
    });

  }

  SaveTimeZone(timeZone: string) {
    console.log("SaveTimeZone Clicked", timeZone);


    this.forensicUserProfileService.setTimeZone(timeZone)
      .subscribe((result) => {

        console.log("SaveTimeZone Saved = ", timeZone);
        this.isTimeZoneSet = true;
        this.updateParentTimeZoneSet(timeZone);
        this.hide();


      },
        (error: HttpErrorResponse) => {
          console.error("ERROR --->");
          console.error(error);
          this.toastr.error("Failed to Save... please contact support", "ERROR");
        });


  }

  onDialogClose(event) {

    if (this.isTimeZoneSet == false) {
      // Do not allow Dialog to Close with X button
      this.toastr.warning("Sorry, but you must set your TimeZone in order for your notes to be displayed." +
      "<br><br>If you have any questions, please contact support@forensicnotes.com", "TIME ZONE", 
      {enableHtml: true});
      event.cancel = true;    // To cancel closing of the dialog
    }
    // else.. allow close if terms accepted
  }


}
