import { Injectable , Inject } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG,AppConfig } from '../app-config.module';
import { NotificationModel } from '../domain/Notification';
import { NotificationListModel } from '../domain/NotificationList';
import { protectedResources } from '../auth-config';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig,private httpClient: HttpClient) { }

  url = protectedResources.apiBaseUrl.endpoint;

  GetAllTeamNotificationCategories(): Observable<NotificationModel>{
    const httpPostOptions = {
      withCredentials: true,
      headers: new HttpHeaders({ accept: "text/plain" }),
    };
    return this.httpClient.get<NotificationModel>(this.url + '/api/Notifications/Categories?audience=1',
    httpPostOptions);
  }

  GetAllTeamNotification(): Observable<NotificationListModel>{
    const httpPostOptions = {
      withCredentials: true,
      headers: new HttpHeaders({ accept: "text/plain" }),
    };
    return this.httpClient.get<NotificationListModel>(
      this.url + '/api/Notifications/GetTeamMessages',
      httpPostOptions);
  }

  GetAllNotification(): Observable<NotificationListModel>{
    const httpPostOptions = {
      withCredentials: true,
      headers: new HttpHeaders({ accept: "text/plain" }),
    };

    return this.httpClient.get<NotificationListModel>(
      this.url + '/api/Notifications?numberToDisplay=1000',
    httpPostOptions);
  }


  MarkAsReadNotification(notificationID : number) :Observable<NotificationListModel>{
    
    const httpOptions = {
      withCredentials : true
    }

    const formData = new FormData();

      return this.httpClient.patch<NotificationListModel>(this.url + '/api/Notifications/'+ notificationID +'/MarkAsRead',
      formData,
      httpOptions);
  }


  CreateTeamNotification(cId,nText){
    const formData = new FormData();
    formData.append('categoryID', cId);
    formData.append('message', nText);
   //console.log(formData);

   const httpOptions = {
    withCredentials: true,
  };

  console.log("CreateTeamMessage");

    return this.http.post(this.url + '/api/Notifications/CreateTeamMessage/',
    formData,
    httpOptions);
  }

  MarkAsUnReadNotification(nID : number): Observable<NotificationListModel>{

    const httpOptions = {
      withCredentials: true
    };

    const formData = new FormData();

  
    return this.httpClient.patch<NotificationListModel>(this.url + '/api/Notifications/'+nID+'/MarkAsUnread',
    formData,
    httpOptions);
  }

}
