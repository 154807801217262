// DEV Server
export const environment = {
  production: true,
  hideConsoleLog: false,
  AdvanceMode: 'https://dev-legacy.forensicnotes.com ',
  LoginUrl: 'https://devb2cforensicnotes.b2clogin.com/devb2cforensicnotes.onmicrosoft.com/b2c_1_testmfa/oauth2/v2.0/authorize?client_id=ad345a26-da2a-407b-b9a3-2a803597c6d0',
  FAQ: 'http://forensicnotes.com/faqs',
  RequireAuthentication: true,
  ChunkSize: 1048576,
  notebookPageSize: 10, //,
    // App Insights
    insightsInstrumentationKey: '9e69b704-60ad-4986-a16c-0ca104a5c0d3',
  // auth-config settings...
  clientID: 'ad345a26-da2a-407b-b9a3-2a803597c6d0',       // NOTE - Make sure ID matches ID in LoginURL above
  authorityDomain: 'devb2cforensicnotes.b2clogin.com',
  signUpSignIn: 'B2C_1_testMFA',
  editProfile: 'B2C_1_edit_profile',
  authorityLogin: 'https://devb2cforensicnotes.b2clogin.com/devb2cforensicnotes.onmicrosoft.com/B2C_1_testMFA',
  authorityEdit: 'https://devb2cforensicnotes.b2clogin.com/devb2cforensicnotes.onmicrosoft.com/B2C_1_edit_profile',
  postLogoutRedirectUri: "https://www.forensicnotes.com",
  // API's
  assetsApiEndPoint: 'https://devfn.forensicnotes.com/apiAssets',
  caseManagementApiEndpoint: 'https://devfn.forensicnotes.com/apiCMS',
  organizationApiEndpoint: 'https://devfn.forensicnotes.com/apiOrg',
  apiBaseUrlEndpoint: 'https://devfn.forensicnotes.com/apiNotes',
  // ----------------------------------------------------------
  // WebSocket - SignalR - Does not work via Front Door
  // ----------------------------------------------------------
  apiSignalRSocketApiEndPoint: 'https://apiv3-forensicnotes-development.azurewebsites.net',

  // Scope
  scopes: 'https://devb2cforensicnotes.onmicrosoft.com/a8d7da96-821f-411a-8d0f-7477140fe184/access_as_user',
  
  //  // API's
  //  assetsApiEndPoint: 'https://asset-management-development.azurewebsites.net',
  //  caseManagementApiEndpoint: 'https://case-management-fn-development.azurewebsites.net/',
  //  organizationApiEndpoint: 'https://fnorganizationapi-development.azurewebsites.net',
  // //  apiBaseUrlEndpoint: 'https://apiv3-forensicnotes-development.azurewebsites.net',
  //  apiBaseUrlEndpoint: 'https://devfn.forensicnotes.com/Notes',


};

